import React from 'react'
import { Stack, alpha, useTheme } from "@mui/system"
import { useScrollTrigger, Fade, Button } from '@mui/material';
import { MUIIcon } from './Icon';

interface ScrollTopT {
    icon?: string;
    render?: JSX.Element
    position?: {
        top?: string | number,
        bottom?: string | number,
        right?: string | number,
        left?: string | number
    }
}

const ScrollTop = ({ icon, render, position }: ScrollTopT) => {
    const trigger = useScrollTrigger({
        target: window,
        threshold: 300,
    });

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <Fade in={trigger}>
            <Button onClick={handleClick}
                sx={{
                    position: 'fixed',
                    zIndex: "fab",
                    top: position?.top,
                    bottom: position?.bottom ?? 16,
                    left: position?.left,
                    right: position?.right ?? 16,
                }}>
                {render ?? <Icon icon={icon} />}
            </Button>
        </Fade >
    );
}

const Icon = ({ icon }: { icon?: string }) => {
    const palette = useTheme().palette;
    return (
        <Stack
            width={55}
            height={40}
            bgcolor={alpha(palette.primary.contrastText, 0.5)}
            justifyContent="center"
            alignItems="center"
        >
            <MUIIcon name={icon ?? "KeyboardArrowUp"} sx={{ fontSize: 40 }} />
        </Stack>
    )
}

export default ScrollTop
