import {Grow, Slide, Typography, useTheme} from '@mui/material';
import React from 'react';
import animocaLogo from '../../image/logo/Animoca_Brands_logo.svg.png';
import varmeegoLogo from '../../image/logo/1620105971552.jpg';
import outblazeLogo from '../../image/logo/Outblaze.png';
import birdieLogo from '../../image/logo/logo.png';
import pixioLogo from '../../image/logo/pixio.jpeg';
import peplinkLogo from '../../image/logo/peplink-vector-logo.png';
import teenLogo from '../../image/original.png';
import EcoSystemSection from './section/EcoSystemSection';
import christianLogo from '../../image/logo/fba2f36a-747b-4ab5-9ac7-7487f692a2e0.jpg';

const ecoSystemData = [
  {
    title: '願景',
    description: '我們一直深信，香港資訊科技未來必須有更多的編程人才，以應對世界科技的飛速發展。',
  },
  {
    title: '使命',
    description: '我們的使命是培訓，聯繫年青人進入科技行業!',
  },
  {
    title: '目標',
    description: '我們目標提供密集、實用的培訓課程，以確保與技術環境相關的，可以進一步脫穎而出。',
  },
];

const logoList = [
  // {
  //   title: "Birdie",
  //   logo: birdieLogo,
  // },
  {
    title: "Varmeego",
    logo: varmeegoLogo,
  },
  {
    title: "Outblaze",
    logo: outblazeLogo,
  },
  {
    title: "Animoca Brands",
    logo: animocaLogo,
    p: 0,
    style: {paddingTop: 20}
  },
  {
    title: "Christian Action",
    logo: christianLogo,
  },
  {
    title: "Pixio Ltd",
    logo: pixioLogo,
    p: 5,
    style: {paddingTop: 10}
  },
  // {
  //   title: "Peplink",
  //   logo: peplinkLogo,
  // },
]

const AboutUsPage = () => {
  const color = useTheme().palette;
  return (
    <Typography color={color.primary.contrastText}>
      <div className='border'></div>
      <div className=' text-white p-5'>
        <h1 className='text-4xl font-bold text-center'>關於我們</h1>
      </div>
      <section className='p-4 lg:p-8 '>
        <div className='container mx-auto space-y-12'>
          <div className='flex flex-col overflow-hidden rounded-md shadow-sm lg:flex-row'>
            <img src={teenLogo} alt='' className='h-80  aspect-video'/>

            <div className='flex flex-col justify-center flex-1 p-6 '>
              <h3 className='text-4xl font-bold'>持續存在</h3>
              <p className='my-6 dark:text-gray-400 text-xl font-bold'>母公司 Teen Future自2015年成立,
                超過3000多名青少年參加遊戲QA培訓</p>

              <p className='my-6 dark:text-gray-400 text-xl font-bold'>
                Teen Future Academy 是於 2015 年在香港成立的 Teen Future 的延伸。 Teen Future
                有著持久的使命和一個優先目標，就是努力創造一個生態園，讓每一個熱愛IT的青年都可以參與並創造他們相信的科技的世界。培養未來的青少年，為希望進入科技行業的學習者提供前端，有趣和實用的科技教育，讓我們的學生能夠通過科技影響社區，提高科技行業的生產力。
              </p>
            </div>
          </div>
        </div>
      </section>

      <EcoSystemSection list={ecoSystemData} image='ecosystem.png'/>

      <section className='py-6 '>
        <div className='container flex flex-col items-center justify-center p-4 mx-auto space-y-8 sm:p-10'>
          <h1 className='text-4xl font-bold leadi text-center sm:text-5xl'>策略伙伴 </h1>

          <div className='flex flex-row flex-wrap-reverse justify-center'>
            {
              logoList.map((item, i) =>
                <Grow key={i} in={true} timeout={1000 + i * 500}>
                  <div className='flex flex-col justify-center m-8 text-center'>
                    <img
                      alt={item.title}
                      className='self-center flex-shrink-0 w-36 h-36 mb-4 bg-center rounded-full dark:bg-gray-500 object-contain'
                      style={{backgroundColor: 'white', padding: item.p ?? 7, ...item.style}}
                      src={item.logo}
                    />
                    <p className='text-xl font-semibold leadi'>{item.title}</p>
                  </div>
                </Grow>
              )
            }
          </div>
        </div>
      </section>
      <div className='border'></div>
    </Typography>
  );
};

export default AboutUsPage;
