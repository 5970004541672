import React from 'react'
import Footer from '../utility/component/Footer'

const list = [
    {
        title: "課程內容",
        list: [
            { text: "課程詳情", direct: "courseDetails/fullStack" },
            { text: "教室地址", direct: `classroomAddress`, },
            { text: "立即報名", direct: `joinNow`, },
        ]
    },
    {
        title: "關於我們",
        list: [
            { text: "關於我們", direct: `aboutUs`, },
            { text: "教學團隊", direct: `teachingTeam`, },
        ]
    },
    {
        title: "一般查詢",
        list: [
            {
                icon: "AssignmentOutlined",
                text: "常見問題",
                direct: `faq`,
            },
            {
                icon: "EmailRounded",
                text: "info@teenfuture.academy",
                direct: "https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=info@teenfuture.academy",
            },
            {
                icon: "WhatsApp",
                text: "9619 2551",
                direct: "https://api.whatsapp.com/send?phone=85296192551",
            },
        ]
    },
]

const bottomInfo = {
    spacing: .7,
    creator: "© 2022 Teen Future Academy",
    list: [
        {
            text: "退款政策",
            direct: "refundPolicy"
        },
        {
            text: "隱私政策",
            direct: "privacyPolicy"
        },
        {
            text: "條款",
            direct: "termsAndCondition"
        },
    ]
}

const TFAFooter = () => {
    return (
        <Footer list={list} bottomInfo={bottomInfo} />
    )
}

export default TFAFooter
