import CourseDetails, { Item } from "../component/CourseDetails";
import React from "react";

const list = [
  {
    label: "上課時間",
    text: ["星期一至五 10:00 - 18:00"],
  },
  {
    label: "修讀時數",
    text: "480小時（約12星期）"
  },
  {
    label: "教室地址",
    text: "荔枝角",
    links: {
      alignItems: "end",
      color: "third.light",
      hoverColor: "third.main",
      iconProps: {
        sx: {
          fontSize: 32
        },
      },
      list: [
        {
          name: "LocationOn",
          direct: "classroomAddress"
        }
      ]
    }
  },
  {
    label: "課程費用",
    text: "HK$9,000 起"
  },
  {
    label: "課程內容",
    text: "會因應就業市場需求而變動"
  },
  {
    label: "教學語言",
    text: "廣東話"
  },
  {
    label: "入學要求",
    type: "list",
    text: [
      "對IT 學習充滿熱誠",
      "準備從事 IT職業",
      "懂得電腦應用技巧",
      "良好中英文"
    ]
  },
] satisfies Item[];

const CourseDetails_FullTime = () => <CourseDetails list={list} />

export default CourseDetails_FullTime;
