import {Container, Stack, alpha, useTheme} from '@mui/system'
import React from 'react'
import {Card, Slide, Typography} from '@mui/material'
import {LinkIconList, LinkIconListT } from '../utility/component/Icon'
import Line from '../utility/component/Line/Line'
import ImageBackground from '../utility/component/ImageBackground'

type ItemT = {
  image: string,
  imageCenter?: string,
  imageProps?: any,
  author: string,
  authorDescription: string,
  description: string,
  timeout?: number,
  links?: LinkIconListT
}

const list = [
  {
    image: "yanSir.jpg",
    imageCenter: "30% 10%",
    imageProps: {
      sx: {
        backgroundSize: "150%",
      }
    },
    author: "Cheng Chung Yan",
    authorDescription: "Lead instructor",
    description: "超過 15 年的 MCU 和固件 (IoT) 開發經驗，包括 C 編程、內核和驅動程序（傳感器）、大學 EE 學生最後一年項目提供意見及支援。 在最近 5 年多的時間裡，我變成了一名全端程序員自由職業者，開發了從前端到後端和數據庫的項目。 目前正在開發物聯網數據項目 – 傳感器到後端數據庫（大數據）到前端（D3 的數據可視化）。 我進入下一階段，為自動分析數據添加 AI",
    links: {
      color: "primary.white",
      hoverColor: "primary.light",
      list: [
        {
          name: "ExploreOutlined",
          direct: "https://teenfuture.net/",
        }
      ]
    }
  },
  {
    image: "chuSir.png",
    imageCenter: "40% 0%",
    imageProps: {
      sx: {
        backgroundSize: "130%",
      }
    },
    author: "Connor Chu",
    authorDescription: "Founder",
    description: "Connor對於年青人總是有特別的抱負.  深信IT就是未來, 年青人就是未來。應對世界科技的快速發展。 2022 年成立青Teen Future Academy 的原因之一是教“你”編碼和 IT 整體成長！共同努力創造一個每個年青人都可以參與, 並創造他們所相信的技術的世界。",
    links: {
      color: "primary.white",
      hoverColor: "primary.light",
      list: [
        {
          name: "LinkedIn",
          direct: "https://www.linkedin.com/in/connor-chu-79888895/",
        },
        {
          name: "ExploreOutlined",
          direct: "https://teenfuture.net/",
        }
      ]
    }
  }
]

const TeachingTeamPage = () => {
  const color = useTheme().palette;

  return (
    <Stack alignItems="center" bgcolor={alpha(color.primary.dark, .7)} p={5} spacing={5}>
      < Typography variant="h3" color="primary.contrastText"> 教學團隊</Typography>
      <Container>
        <Stack direction="row" justifyContent="space-evenly" flexWrap="wrap">
          {list.map((item, i) => <Item key={i} timeout={500 + i * 100} {...item} />)}
        </Stack>
      </Container>
    </Stack>
  )
}

const Item = ({image, imageProps, author, authorDescription, description, links, timeout, imageCenter}: ItemT) => {
  const color = useTheme().palette;

  return (
    <Slide in={true} timeout={timeout}>
      <Stack position="relative" m={3} mt={10}>
        <Card sx={{
          bgcolor: "primary.main",
          maxWidth: 280,
          minHeight: 430,
          position: "relative",
          p: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: 'space-between',
          boxShadow: 10
        }}>
          <Stack spacing={3} alignItems="center" pt={5} pb={3}>
            <Stack spacing={1} alignItems="center">
              <Typography color={alpha(color.secondary.light, .8)} variant="h5" fontWeight="bold"
                          textAlign="center">{author}</Typography>
              <Typography
                sx={{bgcolor: alpha(color.third.light, .9), borderRadius: 5, p: .7, pl: 2, pr: 2}}
                color={alpha(color.primary.main, .9)}
                variant="subtitle2"
                fontWeight="bold">{authorDescription}</Typography>
              <Line height={2} width={1} color={alpha(color.primary.dark, .4)}/>
            </Stack>
            <Typography color={alpha(color.primary.contrastText, .9)} variant="subtitle2"
                        fontWeight="light">{description}</Typography>
          </Stack>
          <Line height={3} width={1} color={alpha(color.primary.dark, .4)}/>
          <Stack mt={2}>
            <LinkIconList {...links} />
          </Stack>
        </Card>
        <Stack alignItems="center">
          <ImageBackground height={120} width={120} image={image} center={imageCenter}
                           {...imageProps}
                           sx={{
                             borderRadius: 100,
                             position: "absolute",
                             top: -80,
                             "&:hover": {
                               transform: "scale(1.2)",
                               backgroundSize: "110%",
                             },
                             ...imageProps?.sx
                           }}/>
        </Stack>
      </Stack>
    </Slide>
  )
}

export default TeachingTeamPage
