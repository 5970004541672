import {CardMedia, useMediaQuery} from "@mui/material";
import React from "react";
import {getImagePath, getVideoPath} from "../../utility";
import Vimeo from '@u-wave/react-vimeo';
import {Stack, useTheme} from "@mui/system";
import YouTube from 'react-youtube';

type VideoT = {
  src: string,
  cover?: string,
  type?: "local" | "youtube",
  youtubeOpts?: any,
  xs?: SizeT
  sm?: SizeT
  md?: SizeT
  lg?: SizeT
  xl?: SizeT,
}

type SizeT = {
  height: string | number,
  width: string | number,
}

const Video = ({src, cover, type, youtubeOpts, ...props}: VideoT & any) => {
  const {xs, sm, md, lg, xl, otherProps} = props;

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isSm = useMediaQuery(theme.breakpoints.down('md'));
  const isMd = useMediaQuery(theme.breakpoints.down('lg'));
  const isLg = useMediaQuery(theme.breakpoints.down('xl'));

  let size: SizeT = xl ?? lg ?? md ?? sm ?? xs ?? {height: "100%", width: "100%"}
  size =
    isXs && xs ? xs :
      isSm && sm ? sm :
        isMd && md ? md :
          isLg && lg ? lg : size;

  switch (type) {
    case "youtube":
      return <YouTube
        videoId={src}
        opts={{
          height: size.height,
          width: size.width,
          ...youtubeOpts,
        }}
        {...props}
      />
    case "vimeo":
      return (
        <Stack height={size.height} width={size.width} bgcolor="black">
          <Vimeo
            video={src}
            height={size.height}
            width={size.width}
            {...props}
          />
        </Stack>
      );
    default:
      return <CardMedia
        component='video'
        controls
        poster={getImagePath(cover)}
        src={getVideoPath(src)}
        {...otherProps}
        sx={{
          width: {
            xs: xs?.width,
            sm: sm?.width,
            md: md?.width,
            lg: lg?.width,
            xl: xl?.width,
          },
          height: {
            xs: xs?.height,
            sm: sm?.height,
            md: md?.height,
            lg: lg?.height,
            xl: xl?.height,
          },
          ...otherProps?.sx
        }}
      />
  }
}

export default Video;
