import React from 'react'
import TextOnlyPage from './TextOnlyPage'

const text = [
    "Terms of Use",
    "These terms and conditions apply to all users of the website (https://teenfuture.academy/) (the “Website”). Your use of the Website or the App is governed by these terms and conditions of use (“Terms of Use”). Please read these Terms of Use carefully before using the Website or the App. By using the Website or the App or any part of them, you agree that you have read these Terms of Use and that you accept and agree to be bound by them.",
    "1. GENERAL",
    ["1.1", "In these Terms of Use the following definitions shall apply:"],
    "a. “Intellectual Property Rights” means any and all intellectual and industrial property rights now in force or that come into force in the future in any part of the world whether or not registered or registerable and includes all applications and rights to apply for registration (and renewals and extensions of any registration) of such rights as well as all rights of action and remedies in relation to past infringements including rights in or in connection with:",
    "(a) confidential information, business or trade secrets, know-how;",
    "(b) inventions, patents (including supplementary protection certificates);",
    "(c) copyright (including the rights to secure copyright renewals and extensions of copyright, copyright not yet in existence but that comes into existence in the future and all other rights of a like nature by law in force in any part of the world);",
    "(d) trade marks, service marks, business or trade names;",
    "(e) design rights, topography rights;",
    "(f) database rights,",
    "together with all other rights of a similar or corresponding character or nature.",
    "b. “Legal Notice and Disclaimer” means the legal notice and disclaimer applicable to the Website and the App as extracted below.",
    "c. “Privacy Policy” means the policy regarding personal data collected in connection with this Website or this App and the privacy of customers and visitors to this Website or this App.",
    ["1.2", "The Legal Notice and Disclaimer and the Privacy Policy are incorporated into these Terms of Use and these documents, along with these Terms of Use, shall prevail over any other document including any of our store policies purporting to have a contractual effect."],
    ["1.3", "You agree to provide complete and updated information for registration on this Website or this App."],
    ["1.4", "You agree not to use the Website or the App for any purpose or in any matter that is unlawful or infringes any third party Intellectual Property Rights."],
    "2. VARIATION OF TERMS OF USE",
    ["2.1", "We reserve the right to amend these Terms of Use from time to time in our sole discretion. Customers and visitors are reminded to review the Terms of Use regularly to ensure that they are familiar with the most recent version. By accessing and using this Website or this App you agree to be bound by the most recent version of these Terms of Use."],
    "3. PREVAILING LANGUAGE",
    ["3.1", "In the event of any discrepancy between the English and Chinese versions of the Terms of Use, the English version shall prevail."],
    "4. APPLICABLE LAW",
    ["4.1", "These Terms of Use shall be governed by the laws of the Hong Kong Special Administrative Region and you agree to submit to the exclusive jurisdiction of the Hong Kong courts for determining any disputes arising for or in relation to these Terms of Use."],
    "5. PERSONAL INFORMATION & PRIVACY",
    ["5.1", "We respect the privacy rights of visitors to the Website or the App. Except where specifically stated, all personal information collected by us shall be handled strictly in accordance with the Privacy Policy."],
    ["5.2", "At times, you may be required to provide us with personal information. Refusal to provide such information may mean that you are unable to access certain parts of this Website or this App, or may render us unable to handle any applications or requests you may make and/or provide you with any products or services. If you are under the age of 18, consent from your parent or guardian is required before you provide us with any personal information."],
    "Legal Notice & Disclaimer",
    ["INTELLECTUAL PROPERTY RIGHTS", "A.1", "This Website or App and any information available on it including, but not limited to, text, graphics, icons, images, software, source code and trade marks, are protected by copyright, design, trade mark and other Intellectual Property Rights owned or controlled by us and/or our licensors. You may not copy, reproduce, republish, upload, download, post, transmit, store in retrieval system, modify, alter or display in public or distribute in any way the contents or any part of this Website or this App for any purpose without our prior written permission except that you may download materials displayed on the site for non-commercial, home personal use only as long as you keep all copyright, trade mark and other proprietary notices intact."],
    ["A.2", "All right, title and interest in the Intellectual Property Rights in this Website or this App shall at all times remain the property of us and/or our licensors. Customers and visitors are not authorized to use the same unless you have obtained express written permission from us and/or our licensors, as the case may be."],
    "B. WARRANTIES",
    ["B.1", "The information provided on this Website or this App is for reference only. To the extent permitted by law, we disclaim all implied and/or express warranties and make no representation as to the accuracy, suitability, applicability or completeness of any information on this Website or this App. We assume no liability for any loss or damage arising from the use or reliance of the information on this Website or this App or for anything posted on or linked to the Website or the App, including without limitation any mistake, error, omission, infringement, defamation, falsehood or other material or omission that might offend or otherwise give rise to any claim or complaint."],
    ["B.2", "We disclaim any endorsement or recommendation of any person, organisation, name, product or service referred to in the Website or the App. We further disclaim all liability in relation to information or materials posted by advertisers or sponsors on this Website or this App."],
    ["B.3", "We make no warranties or representations in respect of your ability to access the Website or the App, and do not warrant that the functions of the Website or the App shall be uninterrupted or error or defect free."],
    ["B.4", "None of our agents or representatives are authorised to make any warranties, representations or statements regarding any information on this Website or this App and we shall not in any way be bound by any such unauthorised warranties, representations or statements."],
    "C. LIMITATION OF LIABILITY",
    ["C.1", "By accessing and using this Website or the App you acknowledge and accept that the use of the Website or the App is at your own risk. We shall not be liable for any direct, indirect, incidental, consequential or punitive damage or for damages for loss of profit or revenue arising out of any use of, access to, or inability to use or access the Website. Without limiting the foregoing:"],
    "THIS WEBSITE OR THIS APP AND ALL INFORMATION AND MATERIALS CONTAINED ON IT ARE PROVIDED “AS IS” WITHOUT ANY WARRANTY OF ANY KIND EITHER EXPRESS OR IMPLIED INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OR IMPLIED TERMS AS TO TITLE, QUALITY, MERCHANTABILITY, FITNESS FOR PURPOSE , PRIVACY OR NON-INFRINGEMENT.",
    "WE HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY ERRORS OR OMISSIONS IN THE CONTENTS OF THE WEBSITE OR THE APP.",
    "WE ASSUME NO RESPONSIBILITY AND SHALL NOT BE LIABLE (TO THE EXTENT PERMITTED BY LAW) FOR ANY DAMAGE OR INJURY ARISING OUT OF ANY USE OF OR ACCESS TO THE WEBSITE OR THE APP, OR ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS, COMMUNICATION LINE FAILURE, INTERCEPTION OF ONLINE COMMUNICATION, SOFTWARE OR HARDWARE PROBLEMS (INCLUDING WITHOUT LIMITATION LOSS OF DATA OR COMPATIBILITY PROBLEMS), THEFT, DESTRUCTION OR ALTERATION OF THE WEBSITE OR THE APP, WHETHER FOR BREACH OF CONTRACT, TORTIOUS BEHAVIOUR, NEGLIGENCE OR, UNDER ANY OTHER CAUSE OF ACTION RESULTING DIRECTLY OR INDIRECTLY FROM ANY ACCESS OR USE OF THE WEBSITE OR THE APP, OR ANY UPLOADING, DOWNLOADING OR PUBLICATION OF DATA, TEXT, IMAGES OR OTHER MATERIAL OR INFORMATION TO OR FROM THE WEBSITE.",
    "D. TERRITORY",
    ["D.1", "The materials on this Website or this APP are directed solely at persons accessing the Website or the App from the Special Administrative Region of Hong Kong. We make no representation that any product or service referred to on this Website or this App is available, or appropriate for use for any other location."],
    "E. CHANGES",
    ["E.1", "We reserve the right, but undertake no duty, to review edit or otherwise change this Website or this App and any information contained on this Website or this App at our sole discretion and without notice."],
    "F. GENERAL",
    ["F.1", "If any provision of these Terms of Use is found to be invalid or unenforceable by a court of law, such invalidity and unenforceability shall not affect the remainder of the Terms of Use which shall continue in full force and effect."],
    ["F.2", "If we do not act in relation to a breach of these Terms of Use by you, this does not waive our right to take action in respect of a similar breach of the Terms of Use by you in the future."]
]

const TermsAndConditionPage = () => {
    return <TextOnlyPage sections={text} />
}

export default TermsAndConditionPage