import React, {ComponentProps} from "react";
import {Box, Button, Typography, useTheme} from "@mui/material";
import {alpha, Stack} from "@mui/system";
import useIsCurPage from "../../../hook/useIsCurPage";
import StyledLink from "../../../component/StyledLink";

export type LinkT = {
  to?: string,
  dir?: string,
  onClick?: () => void,
  text?: string,
  textColor?: string,
  selectedTextColor?: string,
  hoverTextColor?: string,
  containerProps?: ComponentProps<typeof Button>;
  children?: React.ReactNode, //render
} & ComponentProps<typeof Typography>;

export const StyledButton = (
  {
    to,
    dir,
    onClick,
    text,
    textColor = "primary.contrastText",
    selectedTextColor = "primary.light",
    hoverTextColor = "primary.light",
    containerProps,
    sx,
    variant = "body1",
    children,
    ...props
  }: LinkT) => {
  const isCurPage = useIsCurPage({to, dir});
  const linkProps =
    to ? {component: StyledLink, to: to} : {};

  return (
    <Button {...linkProps} onClick={onClick} disableRipple
            {...containerProps}
            sx={{
              p: 0,
              height: 1,
              "&:hover": {
                bgcolor: "transparent",
                "& p": {
                  color: hoverTextColor,
                },
              },
              ...containerProps?.sx
            }}>
      <Typography
        color={isCurPage ? selectedTextColor : textColor}
        {...props}
        sx={{
          typography: variant,
          ...sx
        }}
      >
        {text}
        {children}
      </Typography>
    </Button>
  )
}

export type StyledListProps = {
  links?: LinkT[]
} & LinkT;

export const StyledList = ({links, children, ...props}: StyledListProps) => {
  const color = useTheme().palette;

  return (
    <Box sx={{
      height: 1,
      position: "relative",
      alignItems: "start",
      "& nav": {
        display: "none",
        opacity: 0,
        transition: 'opacity 0.2s ease-in-out',
      },
      "&:hover": {
        "& .header-list-item": {
          color: "primary.light",
        },
        "& nav": {
          display: "flex",
          opacity: 1,
        }
      },
    }}>
      <StyledButton
        className="header-list-item"
        variant="body1"
        {...props}
      >
        {children}
      </StyledButton>
      <Stack
        spacing={2}
        component="nav"
        direction="column"
        sx={{
          position: "absolute",
          bgcolor: alpha(color.primary.main, .9),
          color: "primary.contrastText",
          boxShadow: 3,
          top: "100%",
          right: "50%",
          transform: "translate(50%, 0)",
          width: "max-content",
          m: 0,
          p: 3,
        }}>
        {
          links?.map((item, i) =>
            <StyledButton key={i}
                          textColor="primary.contrastText"
                          hoverTextColor="primary.light"
                          selectedTextColor="primary.light"
                          {...item}
                          sx={{width: 1, p: 0, ...item.sx}}
            />
          )
        }
      </Stack>
    </Box>
  )
}
