import {Stack, alpha, darken, useTheme} from '@mui/system'
import React from 'react'
import {Typography} from '@mui/material'
import {IconBannerT} from "./CardListLayoutType";
import { MUIIcon } from '../Icon';

const CardListLayout1 = ({list}: IconBannerT) => {
    const color = useTheme().palette;

    return (
        <Stack direction="row" flexWrap="wrap" sx={{
            bgcolor: darken(color.primary.main, .2),
            alignItems: "start",
            justifyContent: "center",
            flexWrap: "wrap",
            p: 3,
            width: 1
        }}>
            {list.map((item, i) => (
                <Stack key={i} spacing={2} alignItems="center" justifyContent="start" flex={1} m={2} height={1}>
                    <MUIIcon name={item.icon} sx={{color: alpha(color.primary.white, .9), fontSize: 48, mb: 2}}/>
                    <Typography color={alpha(color.primary.contrastText, .8)} variant="h5"
                                fontWeight="bold">{item.title}</Typography>
                    <Typography color={alpha(color.primary.white, .8)}>{item.description}</Typography>
                </Stack>
            ))}
        </Stack>
    )
}

export default CardListLayout1;
