import {Box} from '@mui/system'
import React from 'react'
import { getImagePath } from '../utility'

export type ImageT = {
    height?: string | number,
    width?: string | number,
    image: string | number,
    center?: string | number,
    children?: JSX.Element
}

const ImageBackground = ({height, width, image, center, children, ...props}: ImageT & any) => {
    return (
        <Box
            height={height ?? 1}
            width={width ?? 1}
            {...props}
            sx={{
                backgroundImage: `url(${getImagePath(image)})`,
                backgroundPosition: center ?? "0%, 0%",
                backgroundSize: 'cover',
                ...props?.sx,
            }}>
            {children}</Box>
    )
}

export default ImageBackground;
