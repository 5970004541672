import React from 'react'
import TextOnlyPage from './TextOnlyPage'

const text = [
    "Data Collection and Use",
    "Your personal data may be collected by us on a voluntary basis when you visit our website, attend our events, use our services, register or communicate with us.",
    "The categories of personal data that we may collect, process and use will include:-",
    [
        "your personal information, contact details such as your name, gender, age, phone number, fax number, correspondence address and email address;",
        "your interests, preferences, opinions and feedback;",
        "your responses to market surveys and contests conducted by us;",
        "and details of your visits to our website and other information collected through cookies and other tracking technology including information that you look at. We may also collect information about you that is publicly available online, including your social media profiles.",
        "We may not collect, process and use your sensitive Personal Data unless your explicit consent has been granted to us.",
    ],
    "Your personal data may be processed and/or used by us for the following main purposes:-",
    [
        "to provide our information and services to you;",
        "to respond to your inquiries;",
        "to mail communications, publications, invitations and such like as you may select, request or as we may consider to be relevant to you;",
        "for general market research;",
        "and for registration or enrollment of events and courses organized by us or our business partners.",
        "The legal basis for our use of your personal data are as follows: -"
    ],
    [
        "Consent: where you have consented to our use of your personal data. You may withdraw your consent to our use of your personal data at any time by contacting us.",
        "Contract performance: where we are required to collect and handle your personal data in order to provide you with the services that we have contractually agreed to provide to you.",
        "Legal obligation: where we need to use your personal data to comply with our legal obligations.",
        "Vital interests: where we need to process your personal data in order to protect the vital interests of you or another natural person.",
        "Public interest: where we need to process your personal data in order to carry out a task that is in the public interest.",
        "Legitimate interests: where we have a legitimate interest in using your personal data or information and such purpose is not outweighed by any interests that you may have, or any prejudice that you may suffer, from the relevant use of your personal data.",
    ],
    "Marketing",
    "We may use your personal data for marketing and promotional purposes. We may communicate marketing and promotions to you by post, telephone, or online (including by email or through your mobile device or via online banner advertisement) and, as appropriate and where required, we will ask you for your consent, or otherwise provide you with the opportunity to choose not to receive marketing, at the time we collect your data.",
    "Data Security",
    "We maintain reasonable safeguard measures to protect your personal data in accordance with the requirements of data protection legislation. All personal data collected by us is stored on secure servers with restricted access by authorized personnel only.",
    "Data Sharing",
    "We keep all personal data confidential except as permitted or required by law. We do not share or disclose any of your personal data with other companies or organizations without your prior consent. However, certain information may be disclosed incidental to or in the course of our operation to the following parties:-",
    [
        "any personnel, agent, adviser, auditor, contractor, or service provider who provide services and advice to us in connection with our operation;",
        "the technicians who help to design and set up our Website;",
        "business partners of the events that you have registered;",
        "and/or the person to whom we are required to make disclosure under any law applicable in or outside Hong Kong.",
    ],
    "Data Retention",
    "Our retention period for personal data are based on business needs and legal requirements. We will retain your personal data for as long as is necessary for the processing purpose(s) for which it was collected and any other permitted linked purpose. Where personal data is no longer needed, we either irreversibly anonymize the data (in which case we may further retain and use the anonymized data) or securely destroy the data.",
    "Your Rights",
    "You have the right to:-",
    [
        "access your personal data and have it corrected, amended or deleted (subject to a reasonable charge);",
        "withdraw your consent to the processing of your personal data at any time;",
        "receive a copy of your personal data and transit such to others;",
        "object to our processing of your personal data;",
        "lodge a complaint about the processing of your personal data with your local data protection authority;",
        "and instruct us to restrict the processing of your personal data pending our investigation and/or verification of your claim.",
    ],
    "Contacting Us",
    "For any data access and correction request or exercise of your rights, please contact our administrative staff for support.",
]

const PrivacyPolicyPage = () => {
    return <TextOnlyPage sections={text} />
}

export default PrivacyPolicyPage