import { Fade, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useState } from 'react'
import VizSensor from 'react-visibility-sensor';
import { MUIIcon } from '../Icon';

type AnimationListT = {
    list?: string[],
    animtaionProps?: any,
    iconProps?: any,
    color?: string,
    dotColor?: string,
    dot?: JSX.Element,
}

const AnimationList = ({ list, animtaionProps, iconProps, color, dotColor, dot }: AnimationListT) => {
    const [active, setActive] = useState(false);

    return (
        <VizSensor onChange={(isVisible: boolean) => setActive(isVisible || active)} >
            <Stack spacing={1.5}>
                {list?.map((item, i) =>
                    <Fade key={i} in={active} timeout={700 * i} {...animtaionProps}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            {dot || <MUIIcon name="FiberManualRecord" sx={{ fontSize: 8, color: dotColor || color || "grey.300" }} {...iconProps} />}
                            <Typography color={color || "grey.300"} >{item}</Typography>
                        </Stack>
                    </Fade>
                )}
            </Stack>
        </VizSensor >
    )
}

export default AnimationList
