import {useLocation} from "react-router-dom";

const useIsCurPage = ({to, dir}: { to?: string, dir?: string }) => {
  const {pathname} = useLocation();
  const path = pathname?.startsWith("/")? pathname.slice(1) : pathname;
  to = to?.startsWith("/") ? to.slice(1) : to;
  dir = dir?.startsWith("/") ? dir.slice(1) : dir;
  return (to && path?.startsWith(to)) || (dir && path?.startsWith(dir));
}

export default useIsCurPage;
