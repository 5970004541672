import React, {useState} from 'react'
import {Container, Stack, alpha, useTheme, darken} from '@mui/system'
import {Button, Typography, Zoom} from '@mui/material'
import Line from '../../../../utility/component/Line/Line'
import ResponsiveVideo from '../../../../utility/component/Video/ResponsiveVideo'
import useDirect from "../../../../hook/useDirect";

const list = [
  "6IVTQV4Otz0",
  "XSWMZ3HuHzU",
  "nyuUUYThYH4",
  "_yDgCc9wXkE",
]

const CourseIntroduction = () => {
  const color = useTheme().palette;
  const [videos, setVideos] = useState<any>({});
  const {directTo} = useDirect();

  return (
    <Container>
      <Line width={1} height={10} color={alpha(color.primary.light, 0.8)} fade={true}/>
      <Stack direction="row"
             sx={{
               flexWrap: {
                 xs: "wrap",
                 sm: "wrap",
                 lg: "inherit",
               },
             }} justifyContent="center">
        <Stack m={2} sx={{
          minWidth: {
            xs: 300,
            sm: 500,
            lg: 600,
          },
        }}>
          {/*<Carousel*/}
          {/*    autoPlay={false}*/}
          {/*    stopAutoPlayOnHover={true}*/}
          {/*    fullHeightHover={true}*/}
          {/*    navButtonsAlwaysVisible={true}*/}
          {/*    interval={10000}*/}
          {/*    onChange={() => {*/}
          {/*        Object.keys(videos).map(item => videos[item].stopVideo())*/}
          {/*    }}>*/}
          {/*    {list.map((item, i) => <ResponsiveYoutube*/}
          {/*        key={i}*/}
          {/*        id={item}*/}
          {/*        xs={{ width: 300, height: 200 }}*/}
          {/*        sm={{ width: 500, height: 300 }}*/}
          {/*        lg={{ width: 600, height: 400 }}*/}
          {/*        onReady={(e) => setVideos((prev: any) => ({ ...prev, [i]: e.target }))}*/}
          {/*    />)}*/}
          {/*</Carousel >*/}

          <ResponsiveVideo
            id={list[0]}
            xs={{width: 300, height: 200}}
            sm={{width: 500, height: 300}}
            lg={{width: 600, height: 400}}
            onReady={(e) => setVideos((prev: any) => ({...prev, [0]: e.target}))}
          />
        </Stack>
        <Stack spacing={1.5} m={2}>
          <Stack direction="row" spacing={2}>
            <Stack direction="row" spacing={1}>
              <Line width={7} height={.7} color={alpha(color.secondary.light, 0.7)} fade={true} fadeDir="to bottom"/>
              <Line width={7} height={1} color={alpha(color.third.light, 0.7)} fade={true} fadeDir="to bottom"/>
            </Stack>
            <Stack spacing={2.5} p={1}>
              <Typography color="primary.contrastText" sx={{
                typography: {
                  sm: 'h5',
                  xs: 'subtitle2'
                }
              }}>天程學院(TFA)專為有志投身IT行業的青年提供可負擔的職業為本資訊科技培訓，協助他們成為資訊科技專才，提升他們的就業能力，並在行業發揮所長。</Typography>
              <Typography color="primary.contrastText" sx={{
                typography: {
                  sm: 'h5',
                  xs: 'subtitle2'
                }
              }}>課程採用職業導向方式，並輔以經驗導師的強大支援。使學員完成培訓後可成功在展示會示範畢業作品，向潛在僱主展示培訓成果。</Typography>
              <Typography color="primary.contrastText" sx={{typography: {sm: 'h5', xs: 'subtitle2'}}}>TFA更是獲社創基金(SIE
                Fund)撥款資助機構，為學員提供相關的培訓。</Typography>
              <Stack alignItems="center">
                <Zoom in={true} timeout={300}>
                  <Button variant="contained" size="large"
                          onClick={() => directTo("joinNow")}
                          sx={{
                            bgcolor: alpha(color.third.main, 1),
                            fontWeight: "bold",
                            width: .9,
                            boxShadow: 20,
                            m: 1,
                            "&:hover": {
                              bgcolor: darken(color.third.main, 0.2)
                            }
                          }}>
                    立即開始
                  </Button>
                </Zoom>
              </Stack>
              <Stack direction="row" justifyContent="flex-end">
                <Line width={.7} height={7} color={alpha(color.primary.light, 0.8)} fade={true} fadeDir="to left"
                      radius={0}/>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  )
}

export default CourseIntroduction
