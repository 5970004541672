import { Collapse, Typography } from '@mui/material'
import { Container, Stack, alpha, useTheme } from '@mui/system'
import React, { useState } from 'react'
import { ButtonIcon } from '../utility/component/Icon'
import Line from '../utility/component/Line/Line'

const list = [
  {
    title: "我可以如何獲得課程證書？",
    text: "凡學員成功完成課程期末的統整項目，均會獲發Full stack developer課程證書乙張。Teen Future Academy天程學院一直與多間科技公司為長期合作夥伴及聯合製作課程，緊貼市場需求。確保每位畢業生將來都能應用所學的技能，對於學員事業轉型及就業前景絕對有所幫助。"
  },
  {
    title: "課程完成後有機會找到工作 ？ ",
    text: "天程學院一直與多間科技公司為長期合作夥伴，及聯合製作課程，緊貼市場需求。確保每位畢業生將來都能應用所學的技能。"
  },
  {
    title: "課程設計有什麼特別之處?",
    text: "對於準備入行的programmer來說，一份專業的個人作品集（Portfolio）是非常重要的求職工具。因此在課堂之中，導師團隊會協助學員製作並寄存屬於自己的作品集，讓學員在堂上應用所學的技能外，同時亦能夠為自己的作品集增值。        除了引導學員製作商業實踐的設計項目，如何揀選合適的作品來吸引僱主/ HR人員的注意亦是一個重點。因此，學員會於堂上學習如何建立一個商業實踐的設計項目，並且有效地於面試中利用作品集來突出自身技能，為入行鋪路。作品集將會成為學員展示整個課程所學的履歷表，讓僱主更了解學員，就業機會大大提升。"
  },
  {
    title: "我需要具備編程方面的背景嗎？",
    text: "不需要。是次課程由初學者角度出發設計，因此學員並不需要具備相關行業背景。"
  },
  {
    title: "我中英文水平都差，數學更不合格都可報讀？",
    text: "對學習編程課程，很多初學者都存有誤解，他們需要很好的數學和英文成績才學得懂。在學習編程的過程中，思維方式比熟讀語言更為重要。初學者更需要的是耐性和邏輯思維，重複練習。我們以運算思維為主的課程會帶領你以正確的方式學習編程。有基本概念更可加速學習進度。我們的教學團隊更會提供即時指導去解決編程時遇上的錯誤及難題。透過緊密交流，我們確保每位學生都能跟上進度。"
  },
  {
    title: "怕課程太深太悶，這不適合我?",
    text: "我們的課程專注於商業實踐，為準備入行程式員 鋪路，課程深入淺出，分為三個階段，作全方位技能覆蓋，讓學員更容易吸收知識，課程為小班教學，更能讓學員更多機會發問問題，讓他們更快學懂電腦語言操作。並且透過與同學一起做 project，一方面可以互相學習，一方面可以學習如何在將來以小組形式與其他程式員工作。"
  },
  {
    title: "是否一定有實習機會，實習時會不會有收入？",
    text: "香港對IT人才需求很大，因此Coding Bootcamp的畢業生要入行不難，實習生更是長時間缺乏。現時僱主招聘態度比以前更開放和更有彈性， 加上天程學院一直與多間科技公司合作，得到僱主認受性，令實習和就業機會大大提升。現時企業重視應徵者的能力，他們主要都是看portfolio，不會過分重視應徵者的學歷及背景，反而是先看性格和態度。另外課程設計除了 學習編程技能，也包含訓練解難能力， 溝通能力， 學習態度。 只要學員肯踏出第一步，建立屬於你自己的portfolio，為未來工作提供參考。"
  },
  {
    title: "除了課堂外，學費還覆蓋了什麼服務？",
    text: "是次課程完結後，學員可以下載課堂上的教學材料作複習用途（請勿轉發予非課程學員，否則本中心保留法律追究之權利），以及使用是次課程導師24/7就業支援服務。"
  },
  {
    title: "有錄影可供學員複習嗎？",
    text: "課程直播完畢後，教學團隊將會於線上資源庫上載課堂錄影，供學員複習之用。"
  },
  {
    title: "我應該如何付款？",
    text: "遞交資料後，課程負責同事會與你聯絡，提供付款方法。"
  },
]

type ItemT = {
  title: string,
  text: string,
}

const FAQPage = () => {
  const color = useTheme().palette;

  return (
    <Stack>
      <Line height={2} width={1} color={alpha(color.primary.contrastText, .4)} />
      <Container>
        <Stack mt={5} mb={5} spacing={5}>
          <Stack spacing={1} alignItems="center">
            <Typography color="primary.contrastText" variant="h4">常見問題</Typography>
          </Stack>
          <Stack m={2} spacing={2}>
            {list.map((item, i) => <Item key={i}  {...item} />)}
          </Stack>
        </Stack>
      </Container>
      <Line height={2} width={1} color={alpha(color.primary.contrastText, .4)} />
    </Stack>
  )
}

const Item = ({ title, text }: ItemT) => {
  const [open, setOpen] = useState(false);
  const color = useTheme().palette;

  return (
    <Stack>
      <Stack
        p={3}
        onClick={() => setOpen((prev) => !prev)}
        sx={{
          bgcolor: alpha(color.primary.dark, open ? 0.5 : .3),
          "&:hover": {
            bgcolor: alpha(color.primary.dark, open ? 0.5 : .4),
            cursor: "pointer",
          },
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography
            color={alpha(color.primary.contrastText, open ? 1 : .8)}
            variant="h6"
            fontWeight={open ? "light" : "bold"}
          >
            {title}
          </Typography>
          <ButtonIcon name={open ? "Remove" : "Add"} color="primary.contrastText" />
        </Stack>
        <Collapse in={open}>
          <Typography color={alpha(color.primary.contrastText, .8)} fontWeight="light" variant="body1" mt={3}>
            {text}
          </Typography>
        </Collapse>
      </Stack>
    </Stack>
  );
};

export default FAQPage
