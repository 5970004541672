import {alpha, Container, Stack, useTheme} from '@mui/system'
import React, {useState} from 'react'
import {Fade, Typography} from '@mui/material'
import VizSensor from 'react-visibility-sensor';
import Carousel from 'react-material-ui-carousel';
import ImageBackground from '../../../utility/component/ImageBackground';
import Line from '../../../utility/component/Line/Line';
import AnimationList from '../../../utility/component/List/AnimationList';

const list = [
  "TFA 讓學員獲得學歷和經驗",
  "獲得工作機會，從做中學",
  "了解巿場所需，為日後入行打好基礎"
]

const LandingShare = () => {
  const [active, setActive] = useState(false);
  const color = useTheme().palette;

  return (
    <Stack
      sx={{
        bgcolor: "primary.main",
        background: `linear-gradient(to left top, ${alpha(color.primary.main, .1)}, ${alpha(color.primary.dark, .5)}, ${alpha(color.primary.dark, 1)})`,
        minHeight: 450
      }}>
      <Container maxWidth="lg" sx={{flex: 1, display: "flex"}}>
        <VizSensor onChange={(isVisible: boolean) => setActive(isVisible || active)}>
          <Stack direction="row" spacing={0} sx={{flex: 1, flexWrap: "wrap"}} mt={3} mb={3}>
            <Stack sx={{flex: 1, justifyContent: "center", alignItems: "start"}}>
              <Stack justifyContent="right" {...style.imageContainer}>
                <Carousel
                  indicators={true}
                  autoPlay={true}
                  stopAutoPlayOnHover={true}
                  duration={1500}
                  fullHeightHover={true}
                  // navButtonsAlwaysInvisible={true}
                  navButtonsAlwaysVisible={true}
                >
                  <ImageBackground {...style.image} image="Cohort1_0.jpg"/>
                  <ImageBackground {...style.image} image="Cohort1_1.jpg"/>
                  <ImageBackground {...style.image} image="Cohort1_2.jpg"/>
                  <ImageBackground {...style.image} image="Cohort1_3.jpg"/>
                  <ImageBackground {...style.image} image="Cohort1_4.jpg"/>
                  <ImageBackground{...style.image} image="Cohort1_5.jpg"/>
                  <ImageBackground {...style.image} image="Cohort1_6.jpg"/>
                </Carousel>
              </Stack>
            </Stack>
            <Stack sx={{flex: 1, justifyContent: "center", minWidth: 250, pt: 2, pb: 2}}>
              <Container>
                <Stack spacing={3}>
                  <Stack spacing={.5}>
                    <Fade in={active}>
                      <Typography color="primary.contrastText" variant="h3">學員分享</Typography>
                    </Fade>
                    <Line width={230} height={4} color={alpha(color.secondary.main, .7)} fade={true}/>
                    <Line width={170} height={4} color={alpha(color.third.main, .7)} fade={true}/>
                  </Stack>
                  <AnimationList list={list}/>
                </Stack>
              </Container>
            </Stack>
          </Stack>
        </VizSensor>
      </Container>
    </Stack>
  )
}

const style = {
  imageContainer: {
    sx: {
      width: {
        xs: "90vw",
        sm: 500,
        lg: 650,
      },
      height: {
        xs: 290,
        sm: 440,
        lg: 490,
      },
    }
  },
  image: {
    sx: {
      width: {
        xs: "90vw",
        sm: 500,
        lg: 650,
      },
      height: {
        xs: 250,
        sm: 400,
        lg: 450,
      },
    }
  }
}

export default LandingShare
