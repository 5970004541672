import React from 'react'
import LandingJoinNow from './section/LandingJoinNow'
import LandingShare from './section/LandingShare'
import LandingBanner from './section/LandingBanner'
import LandingShareCarosel from './section/LandingShareCarosel'
import LandingIconBanner from './section/LandingIconBanner'
import CourseTimeline_FullTime from "../CourseDetailsPage/section/fullTime/CourseTimeline_FullTime";

const LandingPage = () => {
    return (
        <>
            <LandingBanner />
            <LandingIconBanner />
            <CourseTimeline_FullTime />
            <LandingShare />
            <LandingShareCarosel />
            <LandingJoinNow />
        </>
    )
}
export default LandingPage
