import * as React from "react";
import {motion} from "framer-motion";
import {ComponentProps} from "react";

const Path = (props: ComponentProps<typeof motion.path>) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    // stroke="hsl(0, 0%, 18%)"
    strokeLinecap="round"
    {...props}
  />
);

export const MenuToggle = ({isToggle, color}: { isToggle: boolean, color: string }) => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23">
      <Path
        animate={isToggle ? "open" : "closed"}
        variants={{
          closed: {d: "M 2 2.5 L 20 2.5", stroke: color},
          open: {d: "M 3 16.5 L 17 2.5", stroke: color}
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        animate={isToggle ? "open" : "closed"}
        variants={{
          closed: {opacity: 1, stroke: color},
          open: {opacity: 0, stroke: color}
        }}
        transition={{duration: 0.1}}
      />
      <Path
        animate={isToggle ? "open" : "closed"}
        variants={{
          closed: {d: "M 2 16.346 L 20 16.346", stroke: color},
          open: {d: "M 3 2.5 L 17 16.346", stroke: color}
        }}
      />
    </svg>
  )
}
