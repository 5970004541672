import {Button, Fade, Slide, Typography, Zoom} from '@mui/material'
import {Container, Stack, darken, useTheme} from '@mui/system'
import React, {useState} from 'react'
import VizSensor from 'react-visibility-sensor';
import useDirect from '../../../hook/useDirect';
import ImageBackground from '../../../utility/component/ImageBackground';

const LandingJoinNow = () => {
    const [active, setActive] = useState(false);
    const {directTo} = useDirect();
    const color = useTheme().palette;

    return (
        <Stack sx={{bgcolor: "primary.dark", minHeight: 450}}>
            <Container maxWidth="lg" sx={{flex: 1, display: "flex"}}>
                <VizSensor onChange={(isVisible: boolean) => setActive(isVisible || active)}>
                    <Stack direction="row" sx={{flex: 1, m: 2, flexWrap: "wrap-reverse"}}>
                        <Stack spacing={3}
                               sx={{flex: 1, justifyContent: "center", alignItems: "center", minWidth: 250,}}>
                            <Zoom in={active}>
                                <Typography color="primary.contrastText" variant="h3">了解更多</Typography>
                            </Zoom>
                            <Zoom in={active} timeout={300}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    onClick={() => directTo("courseDetails/fullStack")}
                                    sx={{
                                        bgcolor: "third.main",
                                        fontWeight: "bold",
                                        "&:hover": {
                                            bgcolor: darken(color.third.main, .2),
                                        }
                                    }}>
                                    開始
                                </Button>
                            </Zoom>
                        </Stack>
                        <Stack sx={{flex: 1, justifyContent: "center", alignItems: "center",}}>
                            <ImageBackground image="computer-image.jpg" height={1} center="50%, 50%"
                                             sx={{borderRadius: 10, minWidth: 250, minHeight: 250}} />
                        </Stack>
                    </Stack>
                </VizSensor>
            </Container>
        </Stack>
    )
}

export default LandingJoinNow
