import React from 'react'
import {Typography,} from '@mui/material'
import {alpha, Stack, useTheme} from '@mui/system'
import CourseIntroduction from '../component/CourseIntroduction'
import Video from '../../../../utility/component/Video/Video'
import CardListLayout2 from '../../../../utility/component/CardListLayout/CardListLayout2'
import CourseTimeline_FullTime from "./CourseTimeline_FullTime";
import CourseDetails_FullTime from "./CourseDetails_FullTime";

const value = [
  {
    title: "品質",
    description: "學生透過由我們的職業導師和專業專家評估的市場開發項目、來獲得技術知識、解決問題的能力和編碼質量。\n",
  },
  {
    title: "課程實用性",
    description: "教授市場上實用編碼知識，學生透過市場開發項目，以展示他們的能力、持續和專業。我們的團隊仔細評估學生們的表現，並為進入行業奠定了堅實的基礎。\n",
  },
  {
    title: "職業推助",
    description: "TFA 擁有多年長期IT公司合作顆伴，了解市場需求，提供清晰IT 職業路向.  學生將接受全面的培訓，包括了解自己、定制簡歷、現場面試，了解他們的個性、溝通和技術能力，以確保他們適合併能“言行一致，感受到同行power\n",
  },
]

const special = [
  {
    title: "實踐學習",
    description: "課程圍繞市場開發項目。您只需要遵循並添加您自己的想法和內容，為您的作品集和簡歷構建一個很好的商業項目！",
  },
  {
    title: "課程靈活性",
    description: "以港幣 9,000 元或以上的價格選修一門課程，或選修所有課程可享額外特別折扣！",
  },
  {
    title: "隨時幫助",
    description: "如果您遇到困難，將您的問題發布給講師。我們隨時為您提供幫助！我們希望確保您真正理解 並可以實際應用到商業項目中！",
  },
]

const sharing = [
  {
    title: "客戶分享",
    content: "XSWMZ3HuHzU",
  },
  {
    title: "家長分享",
    content: "_yDgCc9wXkE",
  },
  {
    title: "員工分享",
    content: "nyuUUYThYH4",
  },
  {
    title: "學員分享",
    type: "vimeo",
    content: "862448497",
  },
]

const CourseDetailsPage_FullTime = () => {
  const color = useTheme().palette;

  return (
    <Stack sx={{bgcolor: alpha(color.primary.dark, 0.2), flex: 1}}>
      <Stack alignItems="center">
        <Typography variant="h3" color="primary.contrastText" m={3}>課程詳情</Typography>
        <CourseIntroduction/>
        <Stack direction="row" flexWrap="wrap" justifyContent="center" width={1}>
          {
            sharing.map((item, i) =>
              <Stack key={i} p={2} alignItems="center" spacing={3}>
                <Typography variant="h4" color="primary.contrastText">{item.title}</Typography>
                <Video
                  key={i}
                  type={item.type ?? 'youtube'}
                  src={item.content}
                  cover="tfa.png"
                  md={{width: 300, height: 200}}
                  lg={{width: 350, height: 220}}
                />
              </Stack>
            )
          }
        </Stack>
        <CourseTimeline_FullTime/>
        <Stack bgcolor={alpha(color.primary.dark, .2)} minHeight={1} width={1} p={5} alignItems='center'
               spacing={2}>
          <Typography color="primary.contrastText" variant="h3">核心價值</Typography>
          <CardListLayout2 list={value}
                           titleColor={color.primary.contrastText}
                           descriptionColor={alpha(color.primary.contrastText, 0.8)}
                           itemHeight={400}
                           itemWidth={250}
                           itemColor={alpha(color.primary.dark, .7)}
          />
        </Stack>
        <Stack bgcolor={alpha(color.primary.dark, .7)} minHeight={1} width={1} p={5} alignItems='center' spacing={2}>
          <Typography color="primary.contrastText" variant="h3">課程特色</Typography>
          <CardListLayout2 list={special}
                           titleColor={color.primary.contrastText}
                           descriptionColor={alpha(color.primary.contrastText, 0.8)}
                           itemHeight={340}
                           itemWidth={250}
                           itemColor={alpha(color.primary.main, .7)}
          />
        </Stack>
        <CourseDetails_FullTime/>
      </Stack>
    </Stack>
  )
}

export default CourseDetailsPage_FullTime
