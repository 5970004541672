import React from 'react'
import {Typography,} from '@mui/material'
import {alpha, Stack, useTheme} from '@mui/system'
import CourseDetails from '../component/CourseDetails'
import CourseTimeline_PartTime from "./CourseTimeline_PartTime";
import CourseDetails_PartTime from "./CourseDetails_PartTime";

const CourseDetailsPage_PartTime = () => {
  const color = useTheme().palette;

  return (
    <Stack flex={1} bgcolor={alpha(color.primary.dark, 0.2)} sx={{justifyContent: "center"}}>
      <Typography variant="h2"
                  bgcolor={alpha(color.primary.dark, 0.7)}
                  color="primary.contrastText"
                  fontWeight="bold"
                  textAlign="center"
                  pt={6}>
        Full Stack Development (Part Time)
      </Typography>
      <CourseTimeline_PartTime/>
      <CourseDetails_PartTime/>
    </Stack>
  )
}

export default CourseDetailsPage_PartTime
