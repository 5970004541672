const themeConfig = {
  palette: {
    type: "light",
    background: {
      paper: "#05335a",
      default: "#071724"
    },
    common: {
      black: "#000",
      white: "#fff",
    },
    primary: {
      main: "#05335a",
      dark: "#071724",
      light: "#43a2f2",
      contrastText: "rgba(255,255,255,.9)",
      white: "#b2d3ef",
      hyper: "#0ff",
      contrast: "#ffe390",
    },
    secondary: {
      main: "#7abd21",
      dark: "#122000",
      light: "#a3fd2f",
      contrastText: "#fff",
      hyper: "#67ff48",
    },
    third: {
      main: "#c6318c",
      dark: "#510031",
      light: "#ff68c4",
      hyper: "#F446FC",
    },
    contrast: {
      main: "#ffe390",
      light: "#fdb813"
    },
    info: {
      main: "#44a0db",
      light: "#64b6f7",
      dark: "#15437f",
      contrastText: "rgba(0,0,0,0.87)",
    },
    warning: {
      main: "#ed6c02",
      contrastText: "rgba(0,0,0,0.87)",
    },
    success: {
      main: "#5bba59",
      light: "#7bc67e",
      dark: "#3b873e",
      contrastText: "rgba(0,0,0,0.87)",
    },
    error: {
      main: "#ff4848",
      contrastText: "#eff",
    },
    text: {
      primary: "rgba(255,255,255,.9)",
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          letterSpacing: 0.7,
          wordSpacing: 0.2,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 1,
          borderColor: "#C1C2C5",
          borderStyle: "solid",
          borderRadius: 10,
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
          backgroundColor: "#C1C2C5",
          color: "#C1C2C5",
          padding: 10,
        },
      },
    },
  },
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: 'NotoSansHK',
  },
}

export default themeConfig;
