import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import React from 'react'
import YouTube, { YouTubeProps } from 'react-youtube';

type SizeT = {
    height: string | number,
    width: string | number,
}

type ResponsiveYoutubeT = {
    id: string,
    opts?: any
    xs?: SizeT
    sm?: SizeT
    md?: SizeT
    lg?: SizeT
    xl?: SizeT
}

const ResponsiveVideo = ({ id, opts, xs, sm, md, lg, xl, ...props }: ResponsiveYoutubeT & YouTubeProps) => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const isSm = useMediaQuery(theme.breakpoints.down('md'));
    const isMd = useMediaQuery(theme.breakpoints.down('lg'));
    const isLg = useMediaQuery(theme.breakpoints.down('xl'));

    let size: SizeT = xl ?? lg ?? md ?? sm ?? xs ?? { height: "100%", width: "100%" }
    size =
        isXs && xs ? xs :
            isSm && sm ? sm :
                isMd && md ? md :
                    isLg && lg ? lg : size;

    const curOpts = {
        height: size.height,
        width: size.width,
        ...opts,
    };

    return <YouTube videoId={id} opts={curOpts} {...props} />;
}

export default ResponsiveVideo
