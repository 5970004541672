import React from "react";
import { alpha, Stack, useTheme } from "@mui/system";
import axios from "axios";
import { Button, Typography } from "@mui/material";
import Form from "../../utility/component/Form/Form";
import Form_TextField from "../../utility/component/Form/Form_TextField";
import Form_CheckboxSelect from "../../utility/component/Form/Form_CheckboxSelect";

type JoinNowFormT = {
  setLoading: (loading: boolean) => void,
  setSuccess: (loading: boolean) => void
  setError: (error: string) => void
}

const question = [
  {
    question: "姓名",
    formKey: "name",
  },
  {
    question: "電郵地址",
    getError: (value: any) =>
      !value ? "問題為必填項" :
        !/\S+@\S+\.\S+/.test(value) ? '電子郵件格式無效' : "",
    formKey: "email",
  },
  {
    question: "聯絡電話",
    formKey: "phone",
  },
  // {
  //   question: "性別",
  //   formKey: "sex",
  //   options: [
  //     "男",
  //     "女",
  //   ]
  // },
  // {
  //   question: "年齡",
  //   formKey: "age",
  //   options: [
  //     "15-25",
  //     "26-30",
  //     "31-40",
  //   ]
  // },
  // {
  //   question: "教育程度",
  //   formKey: "education",
  //   options: [
  //     "小學", "中學", "文憑 / 證書", "副學士", "學士", "碩士"
  //   ]
  // },
  // {
  //   question: "請問您從何處得知天程學院試堂課程？",
  //   formKey: "whereToKnow",
  //   options: [
  //     "Facebook", "Instagram", "YouTube", "朋友", "天程學院網站", "其他"
  //   ]
  // },
  // {
  //   question: "請問您對程式設計的認識程度是？",
  //   formKey: "knowledge",
  //   options: [
  //     "初學", "基礎", "中級", "高級"
  //   ]
  // },
  // {
  //   question: "請問您對哪些程式設計感興趣？ (可選多於一個)",
  //   formKey: "interest",
  //   maxChoices: -1,
  //   options: [
  //     "網站開發", "系統開發", "手機程式開發", "數據分析", "人工智能", "區塊鏈"
  //   ]
  // },
  // {
  //   question: "請問您為何有興趣報讀TeenFuture Academy的課程? (可選多於一個)",
  //   formKey: "interestToRead",
  //   maxChoices: -1,
  //   options: [
  //     "想找一份相關工作", "想做project freelancer", "持續進修及了解更多"
  //   ]
  // },
  // {
  //   question: "個人資料收集聲明: 我願意收到天程學院的最新資訊或推廣",
  //   formKey: "personalInfoStatement",
  //   options: [
  //     "願意", "不願意"
  //   ]
  // },
]

const JoinNowForm = ({ setLoading, setSuccess, setError }: JoinNowFormT) => {
  const color = useTheme().palette;
  const textColor = color.primary.contrastText;

  const handleSubmit = async (formData: any) => {
    const obj = Object.keys(formData).reduce((prev, cur) => ({ ...prev, [cur]: formData[cur].toString() }), {});
    const response = await axios.post("{BACKEND_URL}prospectiveStudent/apply", obj);
  };

  return (
    <Stack m={3} flex={1} minWidth={.6}>
      <Form initialFormData={{}} onSubmit={handleSubmit} {...{ setLoading, setSuccess, setError }} >
        < Stack spacing={3}>
          <Typography color={alpha(textColor, .8)} variant="subtitle2">
            讓我們了解您的基本信息，以便更好地為您服務
          </Typography>
          {
            question.map((item: any, i) =>
              item.options ?
                <Stack key={i} spacing={1}>
                  <Typography variant="h6" color={alpha(textColor, .7)}>{item.question}</Typography>
                  <Form_CheckboxSelect
                    key={i}
                    label={item.question}
                    formKey={item.formKey}
                    options={item.options}
                    // maxChoices={item.maxChoices}
                    color={alpha(textColor, .8)}
                    checkboxColor={"success"}
                    checkboxProps={{
                      sx: {
                        "& .MuiSvgIcon-root": {
                          color: alpha(textColor, .5),
                        },
                        "&.Mui-checked .MuiSvgIcon-root": {
                          color: alpha(color.primary.hyper, .8),
                        }
                      }
                    }}
                  />
                </Stack>
                :
                <StyledTextField
                  key={i}
                  label={item.question}
                  formKey={item.formKey}
                  error={(value: any) => (item.getError && !!item.getError(value)) ?? !value}
                  helperText={(value: any) => (item.getError && item.getError(value)) ?? (!value && "問題為必填項")}
                />
            )
          }
          <Stack direction="row" justifyContent="start">
            <Button type="submit" variant="contained" sx={{
              bgcolor: alpha(color.third.main, .9),
              "&:hover": {
                bgcolor: alpha(color.third.main, .7),
              }
            }}>
              提交
            </Button>
          </Stack>
        </Stack>
      </Form>
    </Stack>
  );
};

const StyledTextField = ({ title, ...props }: any) => {
  const color = useTheme().palette;
  const textColor = color.primary.contrastText;

  return (
    <Form_TextField
      variant="filled"
      fullWidth
      margin="none"
      sx={{
        borderRadius: 500,
        display: 'flex',
        alignItems: 'center',
        "& .MuiInputBase-input": {
          background: alpha(textColor, .1)
        },
        '& .MuiFormLabel-root': {
          color: alpha(textColor, .9),
        },
        '& label.Mui-focused': {
          color: alpha(textColor, .9),
        },
        "& .MuiFilledInput-underline:after": {
          borderBottomColor: alpha(color.secondary.light, .9),
        },
        "&& .MuiFilledInput-underline:before": {
          borderBottomColor: alpha(textColor, .3),
        },
        "&& .MuiFormHelperText-root": {
          textAlign: "left",
          width: 1,
        }
      }}
      inputProps={{ style: { color: textColor } }}
      {...props}
    />
  )
}

export default JoinNowForm;
