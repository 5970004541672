import * as icons from "@mui/icons-material";
import { Icon, alpha } from "@mui/material";
import { PropsWithChildren } from "react";
import { Stack } from "@mui/system";
import useDirect from "../../hook/useDirect";

export type IconT = {
    name: string,
}

export type ButtonIconT = IconT & {
    onClick?: () => void,
    color?: string,
    hoverColor?: string,
}

export type LinkIconT = ButtonIconT & {
    direct: string,
}

export type LinkIconListT = {
    list?: LinkIconT[],
    color?: string,
    iconProps?: any,
    hoverColor?: string,
}

export const SvgIcon = ({ name, ...props }: PropsWithChildren<IconT>) => {
    switch (name) {
    }
    return <Icon component={icons["Error"]} {...props} />
}

export const MUIIcon = ({ name, color, ...props }: PropsWithChildren<IconT & any>) => {
    const isSvgIcon = name?.endsWith(".svg");
    if (isSvgIcon) return <SvgIcon name={name} {...props} sx={{ color: color, ...props?.sx }} />;
    return <Icon component={icons[name as keyof typeof icons] ?? icons["Error"]} {...props} sx={{ color: color, ...props?.sx }} />;
};

export const ButtonIcon = ({ onClick, color, hoverColor, ...props }: PropsWithChildren<ButtonIconT & any>) => {
    return <MUIIcon
        onClick={onClick}
        {...props}
        sx={{
            color: color || "#fff",
            "&:hover": {
                color: hoverColor || alpha("#fff", .7),
                cursor: "pointer"
            },
            ...props?.sx
        }} />
}


export const LinkIcon = ({ direct, color, hoverColor, ...props }: PropsWithChildren<LinkIconT & any>) => {
    const { directTo } = useDirect();

    return <ButtonIcon
        onClick={() => directTo(direct)}
        color={color}
        hoverColor={hoverColor}
        {...props} />
}

export const LinkIconList = ({ list, color, hoverColor, iconProps, ...props }: PropsWithChildren<LinkIconListT>) => {
    return (
        <Stack direction="row" justifyContent="flex-end" spacing={1}  {...props}>
            {
                list?.map((item, i) => <LinkIcon
                    key={i}
                    name={item.name}
                    color={item.color || color}
                    hoverColor={item.hoverColor || hoverColor}
                    direct={item.direct}
                    {...iconProps} />
                )
            }
        </Stack>
    )
}
