import React from "react";

export const isBrowserDefaultDark = () =>
  window.matchMedia("(prefers-color-scheme: dark)").matches;

export const isVaildEmail = (str) => str.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const padNumber = (num, len = 9) => num.toString().padStart(len, "0");

export const getSpacedFromCamel = (str) => str.replace(/([A-Z])/g, " $1");
export const getSpacedFromCamel_Cap = (str) =>
  getSpacedFromCamel(str.charAt(0).toUpperCase() + str.slice(1));
export const getCamelFromSpaced = (str) =>
  str.replace(/\s(\w)/g, (_, letter) => letter.toUpperCase());
export const getCamelFromSpaced_Low = (str) =>
  getCamelFromSpaced(str.toLowerCase());

export function compareValue(a, b, oper) {
  switch (oper) {
    case "<":
      return a < b;
    case ">":
      return a > b;
    case "<=":
      return a <= b;
    case ">=":
      return a >= b;
    case "===":
      return a === b;
    case "!==":
      return a !== b;
    default:
      throw new Error(`Invalid operator: ${oper}`);
  }
}

export const replaceBraces = (str, replace) =>
  str?.replace(/{([^}]+)}/g, (match, key) => replace);

export const replaceBracesByObj = (str, obj) =>
  str?.replace(/{([^}]+)}/g, (match, key) => obj[key]);

export const getImagePath = (path) => {
  if (path.startsWith("http")) return loadEnvStr(path);
  return loadEnvStr(`{IMG_PREFIX}${path}`);
};

export const getVideoPath = (path) => {
  if (path?.startsWith("http")) return loadEnvStr(path);
  return loadEnvStr(`{VIDEO_PREFIX}${path}`);
};

export const loadEnvStr = (str) => {

  let env = import.meta.env;
  if (env)
    return str?.replace(/{([^}]+)}/g, (match, key) => env[`VITE_${key}`]);

  env = process.env;
  if (env)
    return str?.replace(/{([^}]+)}/g, (match, key) => env[`REACT_APP_${key}`]);

  console.error(".env is not exist")
  return str;
};

export const loadEnvStr_Old = (str) => {
  const env =
    import.meta.env ?? process.env;
  if (!env)
    console.error(".env is not exist")
  return replaceBracesByObj(str, env);
};

export const spaceStrToArray = str => str.trim().split(" ").map(a => `"${a}"`).join(",")

export const getDarkerColor = (hexColor, percentage) => {
  if (hexColor[0] !== "#")
    return hexColor;

  hexColor = hexColor.replace('#', '');

  if (hexColor.length === 3) {
    hexColor = hexColor
      .split('')
      .map(char => char + char)
      .join('');
  }

  const r = parseInt(hexColor.substr(0, 2), 16);
  const g = parseInt(hexColor.substr(2, 2), 16);
  const b = parseInt(hexColor.substr(4, 2), 16);
  const darkR = Math.floor(r * (1 - percentage));
  const darkG = Math.floor(g * (1 - percentage));
  const darkB = Math.floor(b * (1 - percentage));

  const darkerHex = '#' + (darkR * 65536 + darkG * 256 + darkB).toString(16).padStart(6, '0');
  return darkerHex;
}
