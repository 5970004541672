import {Box, Container, Stack, useTheme, alpha} from '@mui/system';
import React from 'react';
import {Slide, Typography, useMediaQuery} from "@mui/material";
import ImageBackground from "../../../utility/component/ImageBackground";

const EcoSystemSection = ({image, list}) => {
  const bp = useTheme().breakpoints;
  const upBp = useMediaQuery(bp.up("md"));

  return (
    <Container>
      <ImageBackground
        image={image}
        center='center'
        sx={{
          float: "left",
          shapeOutside: "polygon(0 0, 75% 10%, 100% 50%, 70% 90%, 0 100%)",
          width: upBp ? 600 : 400,
          height: upBp ? 600 : 400,
        }}/>
      {list?.map((item, i) => (
        <Slide key={i} direction="left" in={true} timeout={500 + 100 * i}>
          <Box className='flex flex-col justify-center flex-1' p={upBp ? 2 : 0} minWidth={250 - i * 50}>
            <Typography variant={upBp ? "h3" : "h4"} fontWeight="lighter">{item.title}</Typography>
            <Typography variant={upBp ? "h6" : "body1"} mt={3} mb={3}
                        className='my-6 dark:text-gray-200 text-xl font-bold'>{item.description}</Typography>
          </Box>
        </Slide>
      ))}
    </Container>
  );
};

export default EcoSystemSection;
