import { Typography } from "@mui/material"
import { Stack, alpha, useTheme } from "@mui/system"

export type LineBreakTextT = {
    text: string | any[],
    textProps?: any,
}

export const LineBreakText = ({ text, textProps }: LineBreakTextT) => {
    return (
        <Stack spacing={3} mt={6} mb={10}>
            <Text text={text} {...textProps} />
        </Stack>
    )
}

const Text = ({ text, ...props }: { text: string | any[] } & any) => {
    const color = useTheme().palette;

    if (!text)
        return <></>;

    if (typeof text === "string")
        return (
            <Typography color={alpha(color.primary.contrastText, 0.8)} {...props} >
                {text}
            </Typography >
        )

    return (
        <Stack >
            {text.map((str: any, i: number) =>
                <Text key={i} text={str} {...props} />
            )}
        </Stack>
    )
}
