import CourseTimeline from "../component/courseTimeline/CourseTimeline";

const data = [
  {
    title: "Module 1",
    subTitle: "Frontend",
    caption: "Week 1 - 3",
    height: 660,
    bottomLogo: [
      {
        image: "logo/html.png",
        height: 80,
        width: 55,
      },
      {
        image: "logo/js.png",
        height: 80,
        width: 55,
      },
      {
        image: "logo/css.png",
        height: 80,
        width: 55,
      },
    ],
    description: [
      {
        title: "Lecture",
        content: [
          "HTML Css JS",
          "Reactjs",
          "RestAPI"
        ]
      },
      {
        title: "Project #1",
        content: [
          "Create Business Case",
          "Website Development",
          "Presentation"
        ]
      },
      {
        title: "Support",
        content: [
          "Mentoring",
          "Online Training Videos",
        ]
      },
    ],
  },
  {
    title: "Module 2",
    subTitle: "Backend",
    caption: "Week 4 - 10",
    height: 660,
    bottomLogo: [
      {
        image: "logo/java.png",
        height: 60,
        width: 120,
      },
      {
        image: "logo/spring-boot.png",
        height: 40,
        width: 100,
      },
    ],
    description: [
      {
        title: "Lecture",
        content: [
          "Java",
          "Relational DB (Mysql)",
          "Spring Boot"
        ]
      },
      {
        title: "Project #2",
        content: [
          "Prototype on Business Case",
          "End-to-end web application Development",
          "Presentation"
        ]
      },
      {
        title: "Support",
        content: [
          "Mentoring",
          "Online Training Videos",
        ]
      },
    ],
  },
  {
    title: "Module 3",
    subTitle: "IT Career Coaching",
    caption: "Week 11 - 12",
    height: 660,
    description: [
      {
        title: "Lecture",
        content: [
          "Introduction on IT Career Path",
          "Technology Direction",
          "Marketing",
          "IT Company Organization",
          "Project Management"
        ]
      },
      {
        title: "Job Interview Training",
        content: [
          "Understanding Yourself",
          "CV preparation",
          "Master Presentation Skills",
          "Build portfolio"
        ]
      },
      {
        title: "Support",
        content: [
          "Mentoring",
          "Practicing your interview",
        ]
      },
    ],
  },
]

const CourseTimeline_FullTime = () => <CourseTimeline data={data}/>
export default CourseTimeline_FullTime;
