import { Button, Typography, alpha } from '@mui/material';
import React from 'react'
import { Stack } from '@mui/system';
import useDirect from '../../hook/useDirect';
import { MUIIcon } from './Icon';

export type ClickAbleTextT = {
    text?: string,
    textProps?: any,
    icon?: string,
    color?: string,
    iconProps?: any,
    hoverColor?: string,
    onClick?: (e: any) => void,
    direct?: string,
}

const ClickAbleText = ({ text, textProps, icon, iconProps, color = "#fff", hoverColor, onClick, direct }: ClickAbleTextT) => {
    const { directTo } = useDirect();

    return (
        <Button
            size="small" variant="text"
            sx={{
                display: "flex", justifyContent: "flex-start", p: 0, minWidth: 0,
                '&:hover .btContent': { // Use '&:hover .btContent' to target the .btContent class inside the button on hover
                    color: hoverColor ?? alpha(color, .7),
                },
            }}
            onClick={e => {
                if (onClick !== undefined) onClick(e);
                if (direct !== undefined) directTo(direct);
            }}>
            <Stack direction="row" spacing={1.5} justifyContent="start">
                {icon && <MUIIcon className="btContent" name={icon} {...iconProps} sx={{
                    color: color,
                    ...iconProps?.sx
                }}
                />}
                <Typography
                    className="btContent"
                    color={color}
                    {...textProps}
                    sx={{
                        textAlign: "left",
                        ...textProps?.sx
                    }}
                >{text}</Typography>
            </Stack>
        </Button>
    )
}

export default ClickAbleText
