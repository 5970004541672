import React, {useContext, useEffect, useState} from "react";
import {TextFieldProps} from "@mui/material/TextField/TextField";
import {TextField} from "@mui/material";
import {FormContext} from "./Form";

type Form_TextFieldT = {
  formKey: string,
  error?: boolean | ((value: any) => boolean),
  helperText?: string | ((value: any) => string),
  onChangeValue?: (value: any) => any,
}

const Form_TextField = (
  {
    formKey,
    error,
    helperText,
    onChangeValue = (value: any) => value,
    ...props
  }: Form_TextFieldT & TextFieldProps) => {

  const {
    submitted,
    formData,
    setFormData,
    addIsValid,
    removeIsValid,
  } = useContext(FormContext);

  const getError = () =>
    typeof error === "function" ?
      (error as (value: any) => boolean)(formData[formKey]) :
      error ?? false;

  const getHelperText = () =>
    typeof helperText === "function" ?
      (helperText as (value: any) => string)(formData[formKey]) :
      helperText ?? "";

  useEffect(() => {
    const getValid = () => !getError();
    addIsValid(getValid);
    return () => removeIsValid(getValid);
  }, [formData]);

  return (
    <TextField
      error={submitted && getError()}
      helperText={submitted && getHelperText()}
      value={formData[formKey] ?? ""}
      onChange={(e) => {
        setFormData((prev: any) => ({...prev, [formKey]: onChangeValue(e.target.value)}));
      }}
      {...props}
    />
  );
};

export default Form_TextField;
