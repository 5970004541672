import CourseTimeline from "../component/courseTimeline/CourseTimeline";

const data = [
  {
    title: "Module 1",
    subTitle: "Frontend",
    caption: "Week 1 - 6",
    height: 660,
    bottomLogo: [
      {
        image: "logo/html.png",
        height: 80,
        width: 55,
      },
      {
        image: "logo/js.png",
        height: 80,
        width: 55,
      },
      {
        image: "logo/css.png",
        height: 80,
        width: 55,
      },
    ],
    description: [
      {
        title: "Lecture",
        content: [
          "Technology Direction",
          "HTML CSS JS",
          "Reactjs",
          "RestAPI",
          "IT Career Path Introduction",
          "IT Company Organization",
        ]
      },
      {
        title: "Project #1",
        content: [
          "Create Business Case",
          "Website Development",
          "Presentation"
        ]
      },
      {
        title: "Support",
        content: [
          "Mentoring",
          "Online Training Videos",
        ]
      },
    ],
  },
  {
    title: "Module 2",
    subTitle: "Backend",
    caption: "Week 7 - 16",
    height: 660,
    bottomLogo: [
      {
        image: "logo/java.png",
        height: 60,
        width: 120,
      },
      {
        image: "logo/spring-boot.png",
        height: 40,
        width: 100,
      },
    ],
    description: [
      {
        title: "Lecture",
        content: [
          "Java",
          "Relational DB (Mysql)",
          "Spring Boot"
        ]
      },
      {
        title: "Project #2",
        content: [
          "Project Management",
          "Prototype on Business Case",
          "End-to-end web application",
          "Development",
          "Presentation",
        ]
      },
      {
        title: "Job Interview Training",
        content: [
          "Understanding Yourself",
          "CV Preparation Skills",
          "Master Presentation Skills",
          "Build Portfolio",
        ]
      },
    ],
  },
]

const CourseTimeline_PartTime = () => <CourseTimeline data={data}/>
export default CourseTimeline_PartTime;
