import React from 'react'
import { Container, Stack, alpha, useTheme } from '@mui/system'
import { Typography } from '@mui/material'
import { getDarkerColor } from '../../utility/utility'
import Line from '../../utility/component/Line/Line'

type TextOnlyPageT = {
    sections: (string | string[])[]
}

const TextOnlyPage = ({ sections }: TextOnlyPageT) => {
    const color = useTheme().palette;

    return (
        <Stack sx={{ flex: 1, bgcolor: getDarkerColor(color.primary.main, 0.1), alignItems: "c" }}>
            <Line width={1} height={4} color={alpha(color.secondary.main, 0.5)} />
            <Line width={1} height={2} color={alpha(color.third.main, 0.5)} />
            <Container>
                <Item sections={sections} />
            </Container>
            <Line width={1} height={4} color={alpha(color.third.light, 0.5)} />
        </Stack >
    )
}

const Item = ({ sections }: TextOnlyPageT) => {
    return (
        <Stack spacing={3} mt={6} mb={10}>
            {sections.map((item, i) =>
                typeof item === "string" ?
                    <Text key={i} >
                        {item}
                    </Text> :
                    <Stack key={i} >
                        {item.map((str, j) =>
                            <Text key={j}>{str}</Text>
                        )}
                    </Stack>
            )}
        </Stack>
    )
}

const Text = ({ children }: { children: string | JSX.Element | never[] }) => {
    const color = useTheme().palette;

    return (
        <Typography variant="inherit" fontWeight="light" sx={{ color: alpha(color.primary.contrastText, 0.8) }}>
            {children}
        </Typography>
    )
}

export default TextOnlyPage
