// const list = [
//     {
//         icon: "AccessTimeOutlined",
//         title: "四個月",
//         description: "用四個月時間，掌握職場必要技能。",
//     },
//     {
//         icon: "DoneOutlineRounded",
//         title: "低門檻",
//         description: " 只要你有基本邏輯，對數學不抗拒，你就合資格成為專才。",
//     },
//     {
//         icon: "HandshakeOutlined",
//         title: "直接請",
//         description: "我們擁有龐大的商界網絡，為你物色潛在僱主，進入IT界。",
//     },
// ]

import CardListLayout1 from "../../../utility/component/CardListLayout/CardListLayout1";

const list = [
    {
        icon: "AccessTimeOutlined",
        title: "行動",
        description: "用四個月時間，掌握職場必要技能。",
    },
    {
        icon: "DoneOutlineRounded",
        title: "連接",
        description: " 只要你有基本邏輯，對數學不抗拒，你就合資格成為專才。",
    },
    {
        icon: "HandshakeOutlined",
        title: "合作",
        description: "我們擁有龐大的商界網絡，為你物色潛在僱主，進入IT界。",
    },
]

const LandingIconBanner = () => {
    return <CardListLayout1 list={list} />
}

export default LandingIconBanner
