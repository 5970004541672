import { Button, Typography } from '@mui/material'
import { Box, Stack, alpha, useTheme } from '@mui/system'
import React from 'react'
import { getImagePath } from '../../../utility/utility';
import useDirect from '../../../hook/useDirect';

const LandingBanner = () => {
  const color = useTheme().palette
  const { directTo } = useDirect();

  return (
    <Stack sx={{
      position: 'relative',
      width: 1,
      height: 1,
      minHeight: "75vh",
    }}>
      <Stack direction="row" flex={1} flexWrap="wrap-reverse">
        <Stack bgcolor={alpha(color.primary.dark, .8)} justifyContent="center" alignItems="center"
          flex={4}
          p={2}
          sx={{
            background: `linear-gradient(to right, ${alpha(color.primary.dark, 1)}, ${alpha(color.primary.dark, .9)}, ${alpha(color.primary.dark, .3)})`,
            minWidth: {
              xs: 300,
              sm: 500,
            }
          }}>
          <Stack spacing={5}>
            <Stack spacing={2}>
              {/*<Typography color="primary.contrastText" {...style.title} >為未來，建造科技！</Typography>*/}
              {/*<Typography color="primary.contrastText"  {...style.title} >為科技，造就人才！</Typography>*/}
              <Typography color={alpha(color.secondary.hyper, .9)} {...style.title} >一站走進IT編碼領域</Typography>
              <Typography color={alpha(color.secondary.light, .9)}  {...style.title2} >入職軟件工程師訓練課程</Typography>

              {/*<Typography color={alpha(color.primary.contrastText, .7)}  {...style.title2} >one stop IT specialist!</Typography>*/}
            </Stack>
            <Stack spacing={.5}>
              {/*<Typography*/}
              {/*    color={alpha(color.secondary.hyper, .9)} {...style.subTitle} >一站走進IT編碼領域</Typography>*/}
              {/*<Typography*/}
              {/*    color={alpha(color.secondary.hyper, .9)} {...style.subTitle} >入職軟件工程師訓練課程</Typography>*/}
              <Typography
                color={alpha(color.primary.contrastText, .9)} variant="body1" fontWeight="bold" >Get trained & matched
              </Typography>
              <Typography
                color={alpha(color.primary.contrastText, .9)} variant="body1" fontWeight="bold"  >with your IT developer job in three months
              </Typography>
            </Stack>

            <Stack direction="row">
              <Button
                variant="contained"
                size="large"
                onClick={() => directTo("courseDetails/fullStack")}
                sx={{
                  bgcolor: alpha(color.third.main, 1),
                  color: "primary.contrastText",
                  fontWeight: "bold",
                  boxShadow: 3,
                  "&:hover": {
                    bgcolor: alpha(color.third.main, .4),
                  }
                }}>
                <Typography sx={{
                  typography: { sm: 'inherit', xs: 'caption' },
                  fontWeight: { sm: 'bold', xs: 'bold' }
                }}>
                  立即行動
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <Stack flex={5} position="relative" sx={{
          display: {
            md: "flex",
            // xs: "none",
          },
          minWidth: {
            xs: 300
          },
          minHeight: 200
        }}>
          <Background />
        </Stack>
      </Stack>
    </Stack>
  )
}

const Background = () => {
  const color = useTheme().palette

  return <>
    <Box
      component="img"
      src={getImagePath("landingBackground/landingBackground.jpg")}
      sx={{
        position: 'absolute',
        width: 1,
        height: 1,
        objectFit: 'cover',
        // filter: 'blur(4.5px)',
        maskSize: 'cover',
        objectPosition: '30% 80%',
      }}
    />
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: 1,
        height: 1,
        // backgroundColor: alpha(color.primary.dark, .65),
        backgroundColor: alpha(color.primary.dark, .2),
      }}
    />
  </>
}

const style = {
  title: {
    sx: {
      typography: { sm: 'h2', xs: 'h4' },
      fontWeight: { sm: 'light', xs: 'light' },
    },
    // textAlign: "center" as const,
  },
  title2: {
    sx: {
      typography: { sm: 'h4', xs: 'h5' },
      fontWeight: 'light',
    },
    // textAlign: "center" as const,
  },
  subTitle: {
    sx: {
      typography: { sm: 'h6', xs: 'h6' },
      fontWeight: { sm: 'bold', xs: 'light' },
    },
    // textAlign: "center" as const,
  },
}

export default LandingBanner
