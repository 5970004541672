import React from "react";
import "./App.css";
import {createTheme, responsiveFontSizes, ThemeProvider} from "@mui/material/styles";
import {Stack,} from "@mui/system";
import ScrollTop from "./utility/component/ScrollTop";
import TFAFooter from "./component/TFAFooter";
import {loadEnvStr} from "./utility/utility";
import axios from "axios";
import Pages from "./Pages";
import Header from "./utility/component/Header/Header";
import themeConfig from "./style/theme";

axios.interceptors.request.use((config) => {
  if (config.url) config.url = loadEnvStr(config.url);
  return config;
});

function App() {
  let theme = createTheme(themeConfig);
  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      {/*<ChatBot />*/}
      <ScrollTop/>
      <Stack minHeight="100vh" bgcolor="primary.main">
        {/*<PromoHeader />*/}
        {/*<TFAHeader/>*/}
        <Header/>
        <Stack flex={1}>
          <Pages/>
        </Stack>
        <TFAFooter/>
      </Stack>
    </ThemeProvider>
  );
}

export default App;
