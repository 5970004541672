import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { Container, Stack, alpha, useTheme, Box } from '@mui/system';
import JoinNowForm from "./JoinNowForm";
import useDirect from "../../hook/useDirect";
import { QRCodeCanvas } from 'qrcode.react';
import {loadEnvStr} from '../../utility/utility';
import Line from '../../utility/component/Line/Line';
import List from '../../utility/component/List/List';

const JoinNowFormPage = () => {
  const color = useTheme().palette;
  const textColor = color.primary.contrastText;
  const { directTo } = useDirect();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  if (loading)
    return (
      <Stack justifyContent="center" alignItems="center" flex={1} bgcolor={alpha(color.primary.dark, .5)}>
        < Typography color="primary.contrastText" variant="h3">您的請求正在提交，請稍候...</Typography>
      </Stack>
    )

  if (success)
    return (
      <Stack justifyContent="center" alignItems="center" flex={1} spacing={5}
        bgcolor={alpha(color.primary.dark, .5)}>
        <Stack spacing={1} alignItems="center">
          < Typography color="primary.contrastText" variant="h4">您的申請已成功提交！</Typography>
          < Typography color="primary.contrastText"
            variant="h4">課程顧問將與您聯絡，回答您的問題並指導您進行下一步操作!</Typography>
        </Stack>
        <Button variant="contained" color="secondary" size="large" onClick={() => directTo("")}>返回</Button>
      </Stack>
    )


  if (error)
    return (
      <Stack justifyContent="center" alignItems="center" flex={1} spacing={5}
        bgcolor={alpha(color.primary.dark, .5)}>
        <Stack spacing={1} alignItems="center">
          < Typography color="primary.contrastText" variant="h4">發生錯誤!</Typography>
          < Typography color="primary.contrastText"
            variant="h4">{error}</Typography>
        </Stack>
        <Button variant="contained" color="error" size="large" onClick={() => setError("")}>返回</Button>
      </Stack>
    )

  return (
    <Stack bgcolor={alpha(color.primary.contrastText, .0)} flex={1}>
      <Line height={2} width={1} color={alpha(textColor, .5)} />
      <Container>
        <Stack alignItems="center" flex={1}>
          <Stack width={1} m={3} spacing={1}>
            <Typography variant="h6" color={alpha(color.secondary.light, .8)}>一站走進IT編碼領域
              軟件工程師訓練課程</Typography>
            <Typography variant="h2" color={alpha(color.primary.hyper, .9)}
              fontWeight="bold">立即開始你的申請</Typography>
          </Stack>
          <Stack direction="row" flexWrap="wrap" width={1} height={1} m={2} p={1}
            bgcolor={alpha(color.primary.dark, .35)}>
            <Typography variant="h5" color={textColor} m={1} ml={3} fontWeight="bold">基本資訊</Typography>
            <Line height={2} width={1} color={alpha(textColor, .5)} />
              <JoinNowForm setLoading={setLoading} setError={setError} setSuccess={setSuccess} />
            <Stack alignItems="center" flex={1}>
              <Stack bgcolor={alpha(color.primary.dark, .6)} p={3} m={3} spacing={2} minWidth={250}
                maxWidth={300}>
                <Typography color={textColor} variant="body1">請填寫您的基本資訊以便：</Typography>
                <List
                  list={[
                    "收取課程大綱",
                    "課程顧問將與您聯絡，回答您的問題並指導您進行下一步操作。"
                  ]}
                  dotColor="third.light"
                  iconProps={{ sx: { fontSize: 8 } }}
                  textProps={{ variant: "subtitle2", color: textColor }}
                />
                <Stack alignItems="center" pt={3} spacing={3}>
                  <Box bgcolor="#fff" p="4px">
                    <QRCodeCanvas value={loadEnvStr("{BASE_URL}{BASE_PATH}#/joinNow")} />
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  )
}


export default JoinNowFormPage
