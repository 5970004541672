import { Stack, alpha, useTheme } from '@mui/system'
import React from 'react'
import { Grow, Typography } from '@mui/material'
import { IconBannerT, ItemT } from "./CardListLayoutType";
import { MUIIcon } from '../Icon';

const CardListLayout2 = (
  {
    list,
    containerProps,
    ...props
  }: IconBannerTT) => {
  return (
    <Stack direction="row"
      flexWrap="wrap"
      sx={{
        alignItems: "start",
        justifyContent: "center",
        flexWrap: "wrap",
        width: 1,
      }}
      {...containerProps}>
      {list.map((item, i) =>
        <Item
          key={i}
          {...props}
          {...item} />
      )}
    </Stack>
  )
}


type IconBannerTT = {
  itemWidth?: string | number
  itemHeight?: string | number
  itemColor?: string
} & IconBannerT;

type ItemTT = {
  itemWidth?: string | number
  itemHeight?: string | number
  itemColor?: string
} & ItemT;

const Item = (
  {
    icon,
    title,
    description,
    itemProps,
    iconColor,
    titleColor,
    descriptionColor,
    iconProps,
    titleProps,
    descriptionProps,
    itemWidth,
    itemHeight,
    itemColor,
  }: ItemTT) => {
  const color = useTheme().palette;

  return (
    <Grow in={true} timeout={1000}>
      <Stack bgcolor={itemColor || color.primary.dark} alignItems="center" spacing={5} width={itemWidth || "auto"}
        height={itemHeight || "auto"} p={4} m={2} {...itemProps}>
        <Stack spacing={2} alignItems="center">
          {icon &&
            <MUIIcon name={icon}
              sx={{ color: alpha(iconColor || color.primary.white, .9), fontSize: 48 }} {...iconProps} />}
          <Typography color={titleColor || alpha(color.primary.contrastText, .8)} variant="h5"
            fontWeight="bold" {...titleProps}>
            {title}
          </Typography>
        </Stack>
        <Typography
          color={descriptionColor || alpha(color.primary.white, .8)} {...descriptionProps}>{description}</Typography>
      </Stack>
    </Grow>
  )
}

export default CardListLayout2;
