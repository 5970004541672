import {AppBar, Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import React, {ComponentProps, useEffect, useState} from "react";
import {alpha, Stack} from "@mui/system";
import {StyledButton, StyledList, StyledListProps} from "./HeaderItem";
import {Line, MUIIcon} from "op-react-material-helper";
import {motion, useCycle} from "framer-motion";
import {useLocation} from "react-router-dom";
import {getImagePath} from "../../utility";
import {MenuToggle} from "../Icon/MenuToggle";


const data = (textColor: string): StyledListProps[] => [
  {
    to: "courseDetails/fullStack",
    text: "課程詳情",
    dir: "courseDetails",
    links: [
      {
        to: "courseDetails/fullStack",
        text: "Full Stack Developer",
      },
      {
        to: "/courseDetails/partTime",
        text: "Full Stack (Part Time)",
        // hoverTextColor: alpha(textColor, .6),
        // textColor: alpha(textColor, .6),
      },
    ]
  },
  {
    to: "/classroomAddress",
    text: "教室地址"
  },
  {
    to: "/teachingTeam",
    text: "教學團隊"
  },
  {
    to: "/aboutUs",
    text: "關於我們",
  },
]

const Header = () => {
  const bp = useTheme().breakpoints;
  const upBp = useMediaQuery(bp.up("lg"));
  const color = useTheme().palette;
  const textColor = color.primary.contrastText;
  const bgcolor = color.primary.main;

  return (
    <AppBar position="sticky"
            sx={{
              bgcolor: bgcolor,
              boxShadow: 1,
              height: 90,
              m: 0,
            }}>
      <Stack direction="row" sx={{
        pl: "4dvw",
        pr: "4dvw",
        flex: 1,
        justifyContent: "space-around",
        alignItems: "center",
      }}>
        <StyledButton to="/" flexDirection="row" display="flex" alignItems="center">
          <Box component="img" width={{sm: 65, xs: 65}} height={{sm: 65, xs: 65}}
               src={getImagePath("icon.jpeg")} alt="Logo"/>
          <Stack spacing={1}>
            <Typography variant="h6" color="secondary.light" pl={1}
                        fontFamily="Logo" fontWeight="light">
              Teen Future Academy
            </Typography>
          </Stack>
        </StyledButton>
        <Stack component="nav" direction="row" height={1} alignItems="center">
          {
            upBp && data(textColor).map((item, i) =>
              <React.Fragment key={i}>
                {i !== 0 && <Box width={2} height={14} mt={.35} bgcolor={alpha(color.primary.contrastText, .3)}/>}
                {
                  item.links ?
                    <StyledList pl={1} pr={1} variant="subtitle1"  {...item}/> :
                    <StyledButton pl={1} pr={1} variant="subtitle1" {...item}/>
                }
              </React.Fragment>
            )
          }
          {!upBp && <Drawer textColor={textColor} bgcolor={bgcolor}/>}
        </Stack>
      </Stack>
    </AppBar>
  )
}

const Drawer = ({textColor, bgcolor}: { textColor: string, bgcolor: string }) => {
  const {pathname} = useLocation();
  const [drawer, setDrawer] = useState(false);

  useEffect(() => setDrawer(false), [pathname])

  return (
    <>
      <StyledButton onClick={() => setDrawer(!drawer)} pt={1.5}>
        <MenuToggle isToggle={drawer} color={alpha(textColor, .7)}/>
      </StyledButton>
      {
        <Stack
          component="nav"
          direction="column"
          sx={{
            position: "absolute",
            top: "100%",
            right: 0,
            width: 1,
            zIndex: -1,
          }}>
          <motion.div
            animate={drawer ? "open" : "closed"}
            initial="closed"
            style={{originY: 0}}
            transition={{
              ease: "easeInOut",
              duration: .1,
            }}
            variants={{
              closed: {scaleY: 0, opacity: .5, transitionEnd: {display: 'none'}},
              open: {scaleY: 1, opacity: 1, display: 'block'},
            }}
          >
            <Stack sx={{
              bgcolor: bgcolor,
              color: textColor,
              width: 1,
              height: 1,
              boxShadow: "0 4px 4px -4px gray",
            }}>
              {data(textColor).map((item, i) =>
                <React.Fragment key={i}>
                  {i !== 0 && <Line width={1} height={1.1} color={alpha(textColor, .3)}/>}
                  {
                    item.links ?
                      <DrawerList bgcolor={bgcolor} {...item}/> :
                      <StyledButton
                        variant="subtitle1"
                        bgcolor={bgcolor}
                        textAlign="left"
                        p={2}
                        width={1}
                        {...item}
                      />
                  }
                </React.Fragment>
              )}
            </Stack>
          </motion.div>
        </Stack>
      }
    </>
  )
}

const DrawerList = ({to, links, ...props}: ComponentProps<typeof StyledList>) => {
  const [showList, setShowList] = useCycle(false, true);

  return (
    <>
      <StyledButton
        textAlign="left"
        p={2}
        width={1}
        onClick={() => setShowList()}
        {...props}
      >
        <MUIIcon name="ArrowDropDown" fontSize="inherit"/>
      </StyledButton>
      <motion.div
        animate={showList ? "open" : "closed"}
        initial="closed"
        style={{originY: 0}}
        transition={{
          ease: "easeInOut",
          duration: .2,
        }}
        variants={{
          open: {scaleY: 1, height: "auto"},
          closed: {scaleY: 0, height: 0}
        }}
      >
        <Stack height={1}>
          {
            links?.map((item, i) =>
              <StyledButton
                key={i}
                bgcolor={props.bgcolor}
                variant="body2"
                textAlign="left"
                p={1.5}
                pl={5}
                width={1}
                {...item}
              />)
          }
        </Stack>
      </motion.div>
    </>
  )
}

export default Header;
