import React, { createContext, useState, useContext, useEffect } from "react";

type FormT = {
  children: JSX.Element;
  initialFormData: any;
  onSubmit?: (formData: any) => void;
  setLoading?: (value: boolean) => void;
  setError?: (value: string) => void;
  setSuccess?: (value: boolean) => void;
};

type FormContextT = {
  submitted: boolean;
  loading: boolean;
  error: string;
  success: boolean;
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  addIsValid: (isValid: () => boolean) => void;
  removeIsValid: (isValid: () => boolean) => void;
};

export const FormContext = createContext<FormContextT>({
  submitted: false,
  loading: false,
  error: "",
  success: false,
  formData: {},
  setFormData: () => {
  },
  addIsValid: () => {
  },
  removeIsValid: () => {
  },
});

const Form = ({ children, initialFormData, onSubmit, setLoading, setError, setSuccess }: FormT) => {
  const [formData, setFormData] = useState(initialFormData);
  const [isValid, setIsValid] = useState<(() => boolean)[]>([]);

  const [submitted, setSubmitted] = useState(false);
  const [loading, setCurLoading] = useState(false);
  const [error, setCurError] = useState("");
  const [success, setCurSuccess] = useState(false);

  const submit = async (e: any) => {
    e.preventDefault();

    setSubmitted(true);
    if (!validateForm()) return;

    setLoading && setLoading(true)
    setCurLoading(true)
    try {
      if (onSubmit)
        await onSubmit(formData);
      setSuccess && setSuccess(true)
      setCurSuccess(true)
    } catch (error: any) {
      setError && setError(error.message)
      setCurError(error.message)
    } finally {
      setLoading && setLoading(false)
      setCurLoading(false)
    }
  };

  const validateForm = () =>
    isValid.reduce((prev, cur) => prev && cur(), true);

  const contextValue = {
    submitted,
    loading,
    error,
    success,
    formData,
    setFormData,
    addIsValid: (isValid: () => boolean) => {
      setIsValid(prev => [...prev, isValid]);
    },
    removeIsValid: (isValid: () => boolean) => {
      setIsValid(prev => prev.filter(item => item !== isValid));
    },
  };

  return (
    <FormContext.Provider value={contextValue}>
      <form onSubmit={submit}>
        {children}
      </form>
    </FormContext.Provider>
  );
};

export default Form;
