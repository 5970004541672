import {alpha} from "@mui/system";
import Form_TextField from "../utility/component/Form/Form_TextField";
import React from "react";
import exp from "constants";
import {useTheme} from "@mui/material";

const StyledTextField = ({...props}: any) => {
  const color = useTheme().palette;
  const textColor = color.primary.contrastText;

  return (
    <Form_TextField
      variant="filled"
      fullWidth
      margin="none"
      sx={{
        borderRadius: 500,
        display: 'flex',
        alignItems: 'center',
        "& .MuiInputBase-input": {
          background: alpha(textColor, .1)
        },
        '& .MuiFormLabel-root': {
          color: alpha(textColor, .9),
        },
        '& label.Mui-focused': {
          color: alpha(textColor, .9),
        },
        "& .MuiFilledInput-underline:after": {
          borderBottomColor: alpha(color.secondary.light, .9),
        },
        "&& .MuiFilledInput-underline:before": {
          borderBottomColor: alpha(textColor, .3),
        },
        "&& .MuiFormHelperText-root": {
          textAlign: "left",
          width: 1,
        }
      }}
      inputProps={{style: {color: textColor}}}
      {...props}
    />
  )
}

export default StyledTextField;
