import {Typography} from '@mui/material'
import {Stack} from '@mui/system'
import React from 'react'
import { MUIIcon } from '../Icon'

export type ListT = {
    list?: string[],
    spacing?:  number
    iconProps?: any,
    textProps?: any,
    containerProps?: any,
    color?: string,
    dotColor?: string,
    dotSize?: number,
    dot?: Element,
    textRender?: (text: string) => JSX.Element,
}

const List = ({list, spacing, iconProps, textProps, color, dotColor, dotSize, dot, containerProps, textRender}: ListT) => {
    return (
        <Stack spacing={spacing ?? 1.5} {...containerProps}>
            {list?.map((item, i) =>
                <Stack key={i} direction="row" alignItems="center" spacing={1}>
                    <>
                        {dot ?? <MUIIcon name="FiberManualRecord" {...iconProps} sx={{
                            fontSize: dotSize ?? 8,
                            color: dotColor ?? color ?? "grey.300", ...iconProps?.sx
                        }}/>}
                        {
                            (textRender && textRender(item)) ??
                            <Typography color={color ?? "grey.300"}  {...textProps}>{item}</Typography>
                        }
                    </>
                </Stack>
            )}
        </Stack>
    )
}

export default List
