import React from 'react'
import { useNavigate } from 'react-router-dom';
import { loadEnvStr } from "../utility/utility";

const useDirect = () => {
  const nav = useNavigate();

  const directTo = (str: string) => {
    if (str.startsWith('http'))
      return window.open(str);

    return nav("/" + str);
  }

  return { directTo }
}

export default useDirect
