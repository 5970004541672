import Form from "../../utility/component/Form/Form";
import React, {useEffect, useState} from "react";
import {alpha, Container, Stack, useTheme} from "@mui/system";
import axios from "axios";
import {
  DataGrid, GridToolbar,
} from "@mui/x-data-grid";
import StyledTextField from "../../component/StyledTextField";
import {Button, Typography} from '@mui/material';

const AdminPage = () => {
  const color = useTheme().palette;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const onSubmit = async (formData: any) => {
    await axios.post("{BACKEND_URL}auth", formData);
  }

  if (success)
    return <StudentPage/>

  if (loading)
    return (
      <Stack justifyContent="center" alignItems="center" flex={1} bgcolor={alpha(color.primary.dark, .5)}>
        < Typography color="primary.contrastText" variant="h3">Loading...</Typography>
      </Stack>
    )

  if (error)
    return (
      <Stack justifyContent="center" alignItems="center" flex={1} spacing={5}
             bgcolor={alpha(color.primary.dark, .5)}>
        <Stack spacing={1} alignItems="center">
          < Typography color="primary.contrastText" variant="h4">發生錯誤!</Typography>
          < Typography color="primary.contrastText"
                       variant="h4">{error}</Typography>
        </Stack>
        <Button variant="contained" color="error" size="large" onClick={() => setError("")}>返回</Button>
      </Stack>
    )

  return (
    <Stack direction="row" justifyContent="center" alignItems="center" flex={1}>
      <Form initialFormData={{}} onSubmit={onSubmit} setLoading={setLoading} setError={setError}
            setSuccess={setSuccess}>
        <Stack spacing={3} bgcolor={alpha(color.primary.dark, .7)} p={7} pl={6} pr={6}>
          <Typography variant="h3" color={alpha(color.primary.contrastText, .7)}>Login</Typography>
          <StyledTextField
            label="email"
            formKey="email"
            error={(value: any) => !value}
            helperText={(value: any) => !value && "required"}
          />
          <StyledTextField label="password"
                           formKey="password"
                           type="password"
                           error={(value: any) => !value}
                           helperText={(value: any) => !value && "required"}/>
          <Stack direction="row" justifyContent="center">
            <Button type="submit" variant="contained" sx={{
              bgcolor: alpha(color.third.main, .9),
              "&:hover": {
                bgcolor: alpha(color.third.main, .7),
              }
            }}>
              Submit
            </Button>
          </Stack>
        </Stack>
      </Form>
    </Stack>
  )
}

const StudentPage = () => {
  const color = useTheme().palette;
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const call = async () => {
      try {
        const res: any = await axios.get("{BACKEND_URL}prospectiveStudent")
        setRows(res.data)
      } catch (e: any) {
        console.error(e.message)
        setError(e.message);
      }
    }
    call();
  }, [])

  return (
    <Stack flex={1} bgcolor={alpha(color.grey[300], .7)} p={5} sx={{
      background: "radial-gradient(circle, rgba(58,180,173,1) 0%, rgba(29,207,253,0.33655469023546913) 50%, rgba(69,85,252,0.5494398442970938) 100%)",
    }}>
      <Container sx={{flex: 1, display: "flex"}}>
        <Stack flex={1}>
          <DataGrid
            columns={columns}
            rows={rows}
            loading={!error && !rows?.length}
            localeText={{
              noRowsLabel: error ? "Server Error" : "No Data Available",
            }}
            experimentalFeatures={{newEditingApi: true}}
            components={{
              Toolbar: () => <GridToolbar/>
            }}
            sx={style(color).dataGrid}
            disableSelectionOnClick
          />
        </Stack>
      </Container>
    </Stack>
  )
}

const columns = [
  {
    field: 'name',
    header: 'Name',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'email',
    header: 'Email',
    minWidth: 200,
    flex: 1,
  },
  {
    field: 'phone',
    header: 'Phone',
    minWidth: 150,
    flex: 1,
  },
]

const style = (color: any) => ({
  container: {flex: 1, pt: 5, pb: 10, pl: 5, pr: 5},
  dataGrid: {
    flex: 1,
    p: 0,
    border: 0,
    borderRadius: 0,
    boxShadow: 20,
    minHeight: 600,
    bgcolor: alpha(color.common.white, 1),
    color: "common.black",
    "& .MuiDataGrid-columnHeaders": {
      bgcolor: alpha(color.primary.dark, .85),
      color: "common.white",
      borderRadius: 0,
    },
    "& .MuiDataGrid-toolbarContainer": {
      bgcolor: alpha(color.primary.dark, .85),
      color: "common.white",
      borderRadius: 0,
    },
    "& .MuiButton-textPrimary": {color: "common.white",},
    "& .MuiDataGrid-columnHeaderTitleContainerContent .MuiCheckbox-root": {
      color: "grey.300"
    },
    "& .MuiToolbar-root ": {
      display: "flex",
      alignItems: "last baseline"
    },
    "& .MuiSvgIcon-root": {
      color: "common.white"
    },
    '& .MuiDataGrid-menuIconButton': {
      display: 'none',
    },
  },
  toolbar: {
    bgcolor: "grey.800",
    color: "common.white",
    "& .MuiInputBase-root": {color: "common.white"},
  },
  search: {
    flex: 1,
    maxWidth: 250,
    p: 0,
    "& .MuiOutlinedInput-root": {
      backgroundColor: "common.white",
    },
  },
});


export default AdminPage;
