import {Route, Routes} from "react-router-dom";
import LandingPage from "./page/LandingPage/LandingPage";
import AdminPage from "./page/AdminPage/AdminPage";
import PrivacyPolicyPage from "./page/TextOnlyPage/PrivacyPolicyPage";
import RefundPolicyPage from "./page/TextOnlyPage/RefundPolicyPage";
import TermsAndConditionPage from "./page/TextOnlyPage/TermsAndConditionPage";
import AboutUsPage from "./page/AboutUsPage/AboutUsPage";
import TeachingTeamPage from "./page/TeachingTeamPage";
import LocationPage from "./page/LocationPage";
import CourseDetailsPage_FullTime from "./page/CourseDetailsPage/section/fullTime/CourseDetailsPage_FullTime";
import FAQPage from "./page/FAQPage";
import JoinNowFormPage from "./page/JoinNowFormPage/JoinNowFormPage";
import React, {useEffect} from "react";
import CourseDetailsPage_PartTime from "./page/CourseDetailsPage/section/partTime/CourseDetailsPage_PartTime";

type PageT = {
  route: string,
  title: string,
  element: JSX.Element,
}

export const pages: PageT[] =
  [
    {
      route: "/",
      title: "TFA | Teen Future Academy 天程學院",
      element: <LandingPage/>,
    },
    {
      route: "/admin",
      title: "TFA | Admin",
      element: <AdminPage/>,
    },
    {
      route: "/privacyPolicy",
      title: "TFA | 隱私政策",
      element: <PrivacyPolicyPage/>,
    },
    {
      route: "/refundPolicy",
      title: "TFA | 退款政策",
      element: <RefundPolicyPage/>,
    },
    {
      route: "/termsAndCondition",
      title: "TFA | 條款",
      element: <TermsAndConditionPage/>,
    },
    {
      route: "/aboutUs",
      title: "TFA | 關於我們",
      element: <AboutUsPage/>,
    },
    {
      route: "/teachingTeam",
      title: "TFA | 教學團隊",
      element: <TeachingTeamPage/>,
    },
    {
      route: "/classroomAddress",
      title: "TFA | 教室地址",
      element: <LocationPage/>,
    },
    {
      route: "/courseDetails/fullStack",
      title: "TFA | Full Stack Development",
      element: <CourseDetailsPage_FullTime/>,
    },
    {
      route: "/courseDetails/partTime",
      title: "TFA | Part Time",
      element: <CourseDetailsPage_PartTime/>,
    },
    {
      route: "/faq",
      title: "TFA | 常見問題",
      element: <FAQPage/>,
    },
    {
      route: "/joinNow",
      title: "TFA | 立即免費加入",
      element: <JoinNowFormPage/>,
    },
    {
      route: "*",
      title: "TFA | Teen Future Academy 天程學院",
      element: <LandingPage/>,
    },
  ];

export const routes = pages.map(item => item.route);

const Page = ({title, element}: Omit<PageT, "route">) => {
  useEffect(() => {
    document.title = title;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [title]);

  return element;
};

const Pages = () => {
  return (
    <Routes>
      {
        pages.map((item, i) =>
          <Route
            key={i}
            path={item.route}
            element={
              <Page
                title={item.title}
                element={item.element}
              />
            }
          />
        )
      }
    </Routes>
  )
}

export default Pages;
