import React, {useContext, useEffect, useState} from "react";
import {FormContext} from "./Form";
import {alpha, Stack, useTheme} from "@mui/system";
import {Checkbox, FormControlLabel, Typography} from "@mui/material";
import {CheckboxProps} from "@mui/material/Checkbox/Checkbox";

type Form_CheckboxSelectT = {
  formKey: string,
  options: string[]
  label?: string,
  color?: string,
  checkboxColor?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'default',
  minChoices?: number,
  maxChoices?: number,
  checkboxProps?: CheckboxProps,
}

const Form_CheckboxSelect = (
  {
    formKey,
    options,
    label,
    color,
    checkboxColor,
    checkboxProps,
    minChoices = 1,
    maxChoices = 1
  }: Form_CheckboxSelectT) => {

  const {
    submitted,
    formData,
    setFormData,
    addIsValid,
    removeIsValid,
  } = useContext(FormContext);

  const palette = useTheme().palette;

  const [selected, setSelected] =
    useState<{ [key: string]: boolean }>
    (() => {
      const formSelected = formData[formKey]?.reduce((prev: any, cur: string) =>
        ({...prev, [cur]: true}), {});
      return options.reduce((prev, cur) =>
        ({...prev, [cur]: formSelected?.cur ?? false}), {});
    });

  const onChange = (option: string) => {
    const selectedKeys = Object.keys(selected).filter(item => selected[item]);
    if (maxChoices !== -1 && selectedKeys.length > maxChoices - 1) {
      const oldestSelected = selectedKeys.shift(); // Get the oldest selected item
      const updatedSelected = {...selected};
      if (oldestSelected)
        updatedSelected[oldestSelected] = false;
      setSelected(updatedSelected);
    }
    setSelected(prev => ({...prev, [option]: !selected[option]}));
  }

  useEffect(() => {
    setFormData((prev: any) => ({
      ...prev,
      [formKey]: Object.keys(selected).filter(item => selected[item])
    }));
  }, [selected])

  const getError = () => {
    return submitted && Object.values(selected).filter(Boolean).length < minChoices;
  };

  useEffect(() => {
    const getValid = () => !getError();
    addIsValid(getValid);
    return () => removeIsValid(getValid);
  }, [formData]);

  const error = getError();

  return (
    <Stack>
      <Stack spacing={.5}>
        {/*<Typography variant="h6" color={error ? alpha(palette.error.main, .9) : color}>{label}</Typography>*/}
        {error &&
          <Typography variant="caption"
                      color="error.main"
                      ml={0}>{minChoices === 1 ? "問題為必填項" : `最少${minChoices} 個選項。`}</Typography>}
      </Stack>
      {
        options.map((item, i) =>
          <FormControlLabel
            key={i}
            sx={{
              color: color
            }}
            label={item}
            control={
              <Checkbox
                checked={selected[item]}
                color={error ? "error" : checkboxColor}
                onChange={() => onChange(item)}
                {...checkboxProps}
              />
            }
          />
        )
      }
    </Stack>
  )
    ;
};

export default Form_CheckboxSelect;
