import React, {ComponentProps, useState} from 'react'
import {alpha, darken, Stack, useTheme} from '@mui/system'
import {Button, Typography, Zoom} from '@mui/material'
import VizSensor from 'react-visibility-sensor';
import useDirect from '../../../../hook/useDirect'
import {LinkIconList, MUIIcon} from '../../../../utility/component/Icon';
import {LineBreakText} from '../../../../utility/component/Text';
import ImageBackground from '../../../../utility/component/ImageBackground';
import List from '../../../../utility/component/List/List';

export type Item = {
  label: string;
  text: string[] | string;
  links?: Links;
  type?: string;
  props?: ComponentProps<typeof List> | ComponentProps<typeof LineBreakText>;
}

export type Links = {
  alignItems: string;
  color: string;
  hoverColor: string;
  iconProps: ComponentProps<typeof MUIIcon>;
  list: Link[];
}

type Link = {
  name: string;
  direct: string;
}

const CourseDetails = ({list}: { list: Item[] }) => {
  const color = useTheme().palette;
  const {directTo} = useDirect();
  const [active, setActive] = useState(true);

  return (
    <VizSensor onChange={(isVisible: boolean) => setActive(isVisible || active)}>
      <Stack direction="row" bgcolor="primary.dark" alignItems="center" justifyContent="center"
             flexWrap="wrap-reverse" width={1}>
        <Stack spacing={1.5} m={5}>
          {list.map((item, i) => (
            <Stack key={i} direction="row" spacing={2} alignItems="center">
              <Typography color={alpha(color.primary.contrastText, .9)}
                          variant="h5" minWidth={105}>{item.label}:</Typography>
              <Stack direction="row" alignItems="center" spacing={.5}>
                <LinkIconList {...item.links} />
                {
                  item.type === "list" ?
                    <Stack pt={.7}>
                      <List list={Array.isArray(item.text) ? item.text : [item.text]}
                            dotColor="secondary.light"
                            dotSize={10}
                            {...item.props}
                            textProps={{
                              color: alpha(color.primary.contrastText, .9),
                              variant: "body1",
                              ...item.props?.textProps,
                            }}
                      />
                    </Stack>
                    :
                    <LineBreakText
                      text={item.text}
                      {...item.props}
                      textProps={{
                        color: alpha(color.primary.contrastText, .9),
                        variant: "h6",
                        ...item.props?.textProps,
                      }}
                    />
                }

              </Stack>
            </Stack>
          ))}
          <Typography variant="subtitle1"></Typography>
        </Stack>
        <Stack alignItems="center">
          <Stack direction="row" bgcolor="#fff" borderRadius={5} sx={{
            width: {
              sm: 400,
              xs: 290,
            }
          }} justifyContent="center" m={5}>
            <Stack>
              <ImageBackground
                height={300} width={290} center="center"
                image="working.jpg"
                sx={{borderRadius: 5}}/>
            </Stack>
          </Stack>
          <Zoom in={active} timeout={300}>
            <Button variant="contained" size="large"
                    onClick={() => directTo("joinNow")}
                    sx={{
                      bgcolor: "third.main",
                      fontWeight: "bold",
                      width: .9,
                      m: 1,
                      "&:hover": {
                        bgcolor: darken(color.third.main, 0.2)
                      }
                    }}>立即開始</Button>
          </Zoom>
        </Stack>
      </Stack>
    </VizSensor>
  )
}

export default CourseDetails
