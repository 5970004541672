import { Divider, Typography } from '@mui/material'
import { Stack, alpha, useTheme, Container } from '@mui/system'
import ClickAbleText, { ClickAbleTextT } from './ClickAbleText';

type FooterT = {
  list: ItemT[],
  bottomInfo: {
    creator: string,
    list: ClickAbleTextT[]
  }
}

type ItemT = {
  title: string,
  list: ClickAbleTextT[]
}

const Footer = ({ list, bottomInfo }: FooterT) => {
  const palette = useTheme().palette;
  const primaryColor = palette.primary;
  const alphaTextColor = alpha(primaryColor.contrastText, 0.5);

  return (
    <Stack sx={{
      bgcolor: primaryColor.main,
      boxShadow: 5,
    }}>
      <Container>
        <Stack
          direction="row"
          sx={{ p: 10, pb: 0, pt: 3, flexWrap: 'wrap', justifyContent: "center" }}
        >
          {list?.map((item, i) => (
            <Item
              {...item}
              key={i}
            />
          ))}
        </Stack>
      </Container>
      <Stack direction="row"
        sx={{ flexWrap: 'wrap', justifyContent: "space-evenly", alignItems: "center", mb: 1, mt: 5 }}>
        <Stack direction="row" spacing={.7}>
          {
            bottomInfo.list?.map((item, i) =>
              <Stack key={i} direction="row" spacing={.7} sx={{ alignItems: "center" }}>
                {i !== 0 && <StyledDivider color={alphaTextColor} />}
                <ClickAbleText text={item.text} direct={item.direct} color={alphaTextColor}
                  textProps={{ variant: "caption" }} hoverColor="primary.light" />
              </Stack>
            )
          }
        </Stack>
        <Typography color={alphaTextColor} variant="caption">
          {bottomInfo.creator}
        </Typography>
      </Stack>
    </Stack>
  )
}

const Item = ({ title, list }: ItemT) => {
  const palette = useTheme().palette;
  const primaryColor = palette.primary;
  const alphaTextColor = alpha(primaryColor.contrastText, 0.75);

  return (
    <Stack spacing={1} sx={{ m: 2, minWidth: 200 }} flex={1}>
      <Typography color={primaryColor.contrastText} variant="h5"
        sx={{ fontWeight: "bold", mb: 1 }}>{title}</Typography>
      {list.map((item, i) =>
        <Stack key={i} direction="row" spacing={1}>
          <ClickAbleText textProps={{ variant: "subtitle2" }} icon={item.icon} text={item.text}
            direct={item.direct} color={alphaTextColor} hoverColor="primary.light" />
        </Stack>
      )}
    </Stack>
  )
}

const StyledDivider = ({ color }: { color: string }) =>
  <Divider orientation="vertical" sx={{ bgcolor: color, height: 12, textAlign: "center", p: 0.01, borderRadius: 1 }} />

export default Footer
