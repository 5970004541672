import React, {memo, useState} from 'react'
import {Container, Stack, alpha, useTheme} from '@mui/system'
import {Button, Typography} from '@mui/material'
import {GeolocateControl, Map, Marker, NavigationControl} from "react-map-gl";
import MapboxLanguage from "@mapbox/mapbox-gl-language";
import 'mapbox-gl/dist/mapbox-gl.css';
import {loadEnvStr} from '../utility/utility';
import Line from '../utility/component/Line/Line';
import ImageBackground from '../utility/component/ImageBackground';
import {MUIIcon} from '../utility/component/Icon';

const location = {
  lng: 114.14891861089173,
  lat: 22.339489056017925,
}

const LocationPage = () => {
  const color = useTheme().palette;
  const [showEng, setShowEng] = useState(false);

  return (
    <Stack flex={1} justifyContent="space-between">
      <Line width={1} height={3} color={alpha("#fff", 0.5)}/>
      <Container sx={{flex: 1, mt: 5, mb: 5}}>
        <Stack direction="row" flexWrap="wrap" sx={{flex: 1}}>
          <Stack sx={{height: '50vh', maxWidth: 600, minWidth: 320, flex: 1}}>
            {/*<MapBoxMap/>*/}
            <ImageBackground image="campusLocation.png" width={1} height={1} center="70% 0%"/>
          </Stack>
          <Stack spacing={3} m={3}>
            <Stack spacing={.5}>
              <Typography color="primary.contrastText" variant="h3">教室地址</Typography>
              <Line width={260} height={5} color={alpha(color.secondary.light, .7)} fade={true}/>
              <Line width={170} height={5} color={alpha(color.third.main, .7)} fade={true}/>
            </Stack>
            <Stack spacing={2}>
              {
                showEng ?
                  <Stack spacing={.5}>
                    <Typography color="primary.contrastText" variant="h5">Rm 1507, 15/F,</Typography>
                    <Typography color="primary.contrastText" variant="h5">ORIENT INTERNATIONAL TOWER</Typography>
                    <Typography color="primary.contrastText" variant="h5">1016-1018 Tai Nan West Street</Typography>
                    <Typography color="primary.contrastText" variant="h5">Lai Chi Kok</Typography>
                  </Stack> :
                  <Stack spacing={.5}>
                    <Typography color="primary.contrastText" variant="h5">荔枝角大南西街1016至1018號</Typography>
                    <Typography color="primary.contrastText" variant="h5">東方國際大廈</Typography>
                    <Typography color="primary.contrastText" variant="h5">15樓1507室</Typography>
                  </Stack>
              }
              <Stack direction="row">
                <Button variant="contained" size="small" color="secondary"
                        sx={{
                          bgcolor: alpha(color.third.main, .9),
                          "&:hover": {
                            bgcolor: alpha(color.third.main, .7)
                          },
                        }}
                        onClick={() => setShowEng(show => !show)}>
                  <MUIIcon name="GTranslate" color="primary.contrastText"/>
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Container>
      <Line width={1} height={2} color={alpha("#fff", 0.5)}/>
    </Stack>
  )
}

const MapBoxMap = () => {
  const [loaded, setLoaded] = useState(false);

  const addControl = (event: any) => {
    const curMap = event?.target;
    if (!curMap || loaded)
      return;
    setLoaded(true);
    curMap.addControl(new MapboxLanguage({defaultLanguage: "zh-Hant"}))
  };


  return (
    <Map
      mapboxAccessToken={loadEnvStr("{Mapbox_ApiKey}")}
      initialViewState={{
        longitude: location.lng,
        latitude: location.lat,
        zoom: 14,
      }}
      style={{flex: 1}}
      mapStyle="mapbox://styles/mapbox/streets-v11"
      localIdeographFontFamily="'Noto Sans', 'Noto Sans CJK SC', sans-serif"
      onData={addControl}
    >
      <GeolocateControl/>
      <NavigationControl/>
      <Marker longitude={location.lng} latitude={location.lat} anchor="bottom">
        <Stack direction="row" position="relative" justifyContent="center">
          <MUIIcon name="LocationOn" sx={{color: "darkviolet", fontSize: 50,}}/>
          <Stack direction="row" justifyContent="center"
                 width={100}
                 sx={{position: "fixed", bgcolor: "#fff", borderRadius: 1, pt: .7, pb: .7, top: 50, boxShadow: 1}}>
            <Typography variant="caption">東方國際大廈</Typography>
          </Stack>
        </Stack>
      </Marker>
    </Map>
  )
}

export default memo(LocationPage, () => true)
