import {Container, Stack, alpha, useTheme} from '@mui/system';
import React from 'react'
import {Typography, useMediaQuery,} from '@mui/material'
import {MUIIcon} from '../../../../../utility/component/Icon';
import Line from '../../../../../utility/component/Line/Line';
import List from '../../../../../utility/component/List/List';
import ImageBackground, {ImageT} from "../../../../../utility/component/ImageBackground";

type ItemT = {
  title?: string,
  height?: number,
  description?:
    {
      title: string,
      content: string[]
    }[],
  bottomLogo?: ImageT[],
  subTitle?: string,
  caption?: string,
}

const CourseTimeline = ({data}: { data: ItemT[] }) => {
  const theme = useTheme();
  const color = theme.palette;

  return (
    <Stack bgcolor={alpha(color.primary.dark, .7)} sx={{width: 1, pt: 12, pb: 8}}>
      <Container>
        <Stack direction="row">
          <TimelineContainer data={data}/>
        </Stack>
      </Container>
    </Stack>
  )
}

const TimelineContainer = ({data}: { data: ItemT[] }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Stack direction={matches ? "column" : "row"} justifyContent="center" width={1} m={1}>
      {data.map((item, i) =>
        <Stack key={i} width={1} direction={matches ? "column" : "row"} flexWrap={matches ? "wrap" : "nowrap"}
               alignItems="center" justifyContent="center">
          {i !== 0 && <MUIIcon name={matches ? "Reply" : "Reply"} sx={{
            color: "primary.light",
            fontSize: 46,
            m: 1,
            transform: matches ? "scaleX(-1) rotate(-90deg)" : "scaleX(-1)"
          }}/>}
          <Item {...item} />
        </Stack>
      )}
    </Stack>
  )
}

const Item = ({title, subTitle, caption, description, bottomLogo, height}: ItemT) => {
  const color = useTheme().palette;

  return (
    <Stack direction="row" width={{xl: 400, lg: 340, sm: 460, xs: 300}} minHeight={height ?? 630} flex={1}>
      <Stack p={3} spacing={1} flex={1}
             bgcolor={alpha(color.primary.main, .7)}
             borderRadius={5}
             boxShadow={20}
             justifyContent="space-between">
        <Stack>
          <Stack alignItems="center" spacing={1.5}>
            <Stack alignItems="center" width={1} spacing={1}>
              <Typography variant="h4" color={alpha(color.secondary.hyper, .8)}
                          fontWeight="bold">{title}</Typography>
              <Line width={1} height={2} color={color.primary.main}/>
            </Stack>
            <Stack alignItems="center" width={1} spacing={1}>
              <Typography variant="h5" color={alpha(color.secondary.contrastText, .8)}
                          sx={{textDecoration: 'underline'}}>{subTitle}</Typography>
              <Typography variant="body2" color={alpha(color.primary.contrastText, .6)} fontWeight="light"
                          fontStyle="unset">{caption}</Typography>
            </Stack>
          </Stack>
          <Stack spacing={3} mt={3} mb={6}>
            {
              description?.map((item: any, i: number) =>
                <Stack key={i} spacing={.5}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <MUIIcon name="FiberManualRecord"
                             sx={{color: alpha(color.third.light, 1), fontSize: 12}}/>
                    <Typography color={alpha("#fff", .8)} fontWeight="bold">{item.title}</Typography>
                  </Stack>
                  <Container>
                    <List list={item.content} color={alpha("#fff", .8)}
                          dotColor={alpha(color.third.light, 1)}
                          spacing={.5}/>
                  </Container>
                </Stack>
              )
            }
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" flexWrap="wrap">
          {
            bottomLogo?.map((item, i) =>
              <ImageBackground key={i} {...item}/>)
          }
        </Stack>
      </Stack>
    </Stack>
  )
}

export default CourseTimeline
