import React from 'react'
import Carousel from 'react-material-ui-carousel'
import {getDarkerColor} from '../../../utility/utility'
import {Container, Stack, alpha, useTheme} from '@mui/system';
import {Typography} from '@mui/material';
import Line from '../../../utility/component/Line/Line';

const items = [
/*    {
        description: "Teen Future 建議我參加全職的全棧編碼訓練營計劃，並打下良好的基礎，開始我作為軟件開發人員的旅程。 儘管 4 個月的時間很短，節奏很快，但除了課程之外，TFA 還提供了向業內人士學習的機會，並提供了職業指導諮詢。 這確保了我們將準備進入該行業。",
        anthor: "Barry Chan",
        anthorDescription: "Analyst Programmer",
    },*/
    {
        description: "我不會後悔加入Teen Future！！導師團隊提供不間斷的支持，不僅從工作技能或課堂培訓，而且從他們自己的工作經驗和市場變化中，提供有用的建議。 我們就像一家人，互相關心，互相照顧。",
        anthor: "Ming Lao",
        anthorDescription: "Programmer",
    },
    {
        description: "Teen Future 團隊致力於確保所有學生都能獲得豐富的體驗，因此我會樂於傾聽他們的建議，感謝他們的教導！",
        anthor: "Hung Leung",
        anthorDescription: "Programmer",
    },
    {
        description: "開始課程時，我只認識If，If else這些基本邏輯。連Fontend和Backend都分不清。上堂後，首兩星期嘗試學習看code和理解格式，之後在做project中加深理解。經過三個月的課程後，我好高興可以完成一個網頁，由Fontend到Backend的整過過程。報讀這個課程我獲得滿足感和方向，順利找到相關工作。多謝TFA開辦這個課程。",
        anthor: "Emerson",
        anthorDescription: "Programmer",
    },
    {
        description: "雖然課程只有短短三個月，但學到了很多實用的技能和知識。課程後，在Teen Future的幫助下，我亦得到工作的機會，對於沒有任何programmer經驗的我， 這是一個很好的投資和體驗。",
        anthor: "Addy Yeung",
        anthorDescription: "Programmer",
    },
    {
        description: "TFA課程了解市場所需，推薦這個課程給其他有志於學習技術的人。課程之外，TFA 還提供了向業內人士學習的機會，並提供了職業指導諮詢。感謝TFA團隊",
        anthor: "Gary Cheung",
        anthorDescription: "Programmer",
    },
    {
        description: "As a programming course, this course followed a well balance of theory and practice. Rather than simply memorizing programming concepts, students were encouraged to apply them in real-world scenarios. This approach helped me to develop a deeper understanding of programming and gave me the confidence to tackle more complex projects. I would highly recommend this course to anyone who is interested in learning programming in a practical way. ",
        anthor: "Mike Tsui",
        anthorDescription: "Programmer",
    },
]

type ItemT = {
    description: string,
    anthor: string,
    anthorDescription: string,
}

const LandingShareCarosel = () => {
    const plaette = useTheme().palette;

    return (
        <Stack sx={{
            bgcolor: getDarkerColor(plaette.primary.dark, -0.5), p: 1
        }}>
            <Carousel
                autoPlay={true}
                stopAutoPlayOnHover={true}
                duration={1000}
                fullHeightHover={true}
                navButtonsAlwaysInvisible={true}>
                {items.map((item, i) => <Item key={i} {...item} />)}
            </Carousel>
        </Stack>
    )
}

const Item = ({description, anthor, anthorDescription}: ItemT) => {
    const plaette = useTheme().palette;

    return (
        <Stack minHeight={200}>
            <Stack spacing={2} sx={{alignItems: "center", justifyContent: "space-evenly", m: 3, flex: 1}}>
                <Container>
                    <Stack spacing={1.5}>
                        <Typography variant="body1" color={alpha(plaette.primary.contrastText, .8)} textAlign="center">
                            {description}
                        </Typography>
                        <Line width={1} height="1px" color={alpha(plaette.primary.main, .8)}/>
                    </Stack>
                </Container>
                <Stack spacing={0.5}>
                    <Stack>
                        <Typography
                            color={alpha(plaette.primary.contrastText, .6)}
                            variant="body2"
                            textAlign="center"
                            fontWeight="bold">
                            {anthor}
                        </Typography>
                    </Stack>
                    <Typography variant="caption" color={alpha(plaette.primary.contrastText, .5)} textAlign="center">
                        {anthorDescription}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default LandingShareCarosel
