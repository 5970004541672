import {Link, useLocation} from "react-router-dom";
import React from "react";
import {Box} from "@mui/material";

type StyledLinkProps = {
  to: string,
  children?: React.ReactNode;
} & React.ComponentProps<typeof Box>;

const StyledLink = ({to, children, ...props}: StyledLinkProps) => {
  return (
    <Box component={Link} to={to} {...props} sx={{
      textDecoration: "none",
      ...props.sx
    }}>
      {children}
    </Box>
  )
}

export default StyledLink;
