import React from 'react'
import TextOnlyPage from './TextOnlyPage'

const text = [
    "Data Collection and Use",
    "Your personal data may be collected by us on a voluntary basis when you visit our website, attend our events, use our services, register or communicate with us.",
    "The categories of personal data that we may collect, process and use will include:-",
    ["your personal information, contact details such as your name, gender, age, phone number, fax number, correspondence address and email address;", "your interests, preferences, opinions and feedback;", "your responses to market surveys and contests conducted by us;", "and details of your visits to our website and other information collected through cookies and other tracking technology including information that you look at. We may also collect information about you that is publicly available online, including your social media profiles.", "We may not collect, process and use your sensitive Personal Data unless your explicit consent has been granted to us.",],
    "Your personal data may be processed and/or used by us for the following main purposes:-",
    [
        "to provide our information and services to you;",
        "to respond to your inquiries;",
        "to mail communications, publications, invitations and such like as you may select, request or as we may consider to be relevant to you;",
        "for general market research;",
        "and for registration or enrollment of events and courses organized by us or our business partners.",
        "The legal basis for our use of your personal data are as follows: -"
    ],
]

const RefundPolicyPage = () => {
    return <TextOnlyPage sections={text} />
}

export default RefundPolicyPage